<template>
  <div class="record-item">
    <div class="title">
      <div class="title-t">
        <div class="title-left">
          <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
          <span>{{new Date(recordItem.createTime).Format('MM/dd hh:mm')}}</span>
        </div>
        <div class="title-right" :class="recordItem.inSending?'is-sending-class':''">
          应发送{{recordItem.totalNum}}人
          <span v-if="recordItem.inSending">,</span>
          <span class="sending-text" v-if="recordItem.inSending">正在发送</span>
        </div>
      </div>
      <el-progress :percentage="percentage" :stroke-width="3" color="#366aff" :show-text="false" v-if="percentage < 100"></el-progress>
    </div>
    <div class="send-result">
      <div class="send-content">
        <span class="left">
          <template v-if="recordItem.attachment.msgtype ==='text'">
            <span v-html="recordItem.attachment.text.content"></span>
          </template>
          <template v-else>
            {{setAttachment}}
          </template>
        </span>
        <span class="right" @click="lookDetail">查看详情</span>
      </div>
      <div class="result-item success-result">
        <div class="result-title">
          <div class="result-title-left">
            <span class="result-icon">
              <i class="iconfont guoran-tongyichicun-16-17-yiwancheng"></i>
            </span>
            <span class="result-word">发送成功</span>
            <span class="send-number">{{successNumber}}</span>
          </div>
        </div>
        <div class="result-personnel">
          <span v-for="item in personnelList" class="personnel-item">
            <template v-if="item.displayInfo">
              <span class="sign"></span>
              <span class="name">
                <open-data v-if="item.displayInfo.wechatThirdRender" :type="item.displayInfo.type==='MEMBER'?'userName':'departmentName'" :openid="item.displayInfo.name"></open-data>
                <span v-else>{{item.displayInfo.name}}</span>
              </span>
            </template>
          </span>
        </div>
        <div class="lodeMore" v-if="isHasNext" @click="loadMorePerson('success')">
          <i class="iconfont guoran-tongyichicun-dianjichakantuli"></i>
          <span>查看更多</span>
        </div>
      </div>
      <div class="result-item fail-result">
        <div class="result-title">
          <div class="result-title-left">
             <span class="fail-result-icon">
               <i class="iconfont guoran-shanchu"></i>
             </span>
            <span class="result-word">发送失败</span>
            <span class="send-number">{{failNumber}}</span>
          </div>
         <div class="result-title-right" :class="recordItem.inSending ? 'in-sending':''" v-if="failNumber > 0" @click="openFailReason">
           <span>查看失败原因</span>
         </div>
        </div>
        <div class="result-personnel">
          <span v-for="item in failPersonnelList" class="personnel-item">
            <template v-if="item.displayInfo">
              <span class="sign"></span>
              <span class="name">
                <open-data v-if="item.displayInfo.wechatThirdRender" :type="item.displayInfo.type==='MEMBER'?'userName':'departmentName'" :openid="item.displayInfo.name"></open-data>
                <span v-else>{{item.displayInfo.name}}</span>
              </span>
            </template>
          </span>
        </div>
        <div class="lodeMore" v-if="failIsLast" @click="loadMorePerson('fail')">
          <i class="iconfont guoran-tongyichicun-dianjichakantuli"></i>
          <span>查看更多</span>
        </div>
      </div>
    </div>
    <el-drawer
        title="查看详情"
        :visible.sync="viewDetails"
        direction="rtl"
        size="600px"
        :append-to-body="true"
        @close="closeDetail"
        :withHeader="false"
    >
      <div class="dialog-container">
        <div slot="title" class="dialog-header">
          <div class="title-left" @click="closeDetail('back')">
            <i class="iconfont guoran-tongyichicun-18-22-fanhui"></i>
            <span>查看详情</span>
          </div>
          <div class="title-right" @click="closeDetail('close')">
            <i class="iconfont guoran-shanchu"></i>
          </div>
        </div>
        <div class="view-detail-content">
          <preview :welcome-config="welcomeConfig"></preview>
        </div>
      </div>
    </el-drawer>
    <el-drawer
        title="查看详情"
        :visible.sync="lookFailReason"
        direction="rtl"
        size="600px"
        :append-to-body="true"
        :withHeader="false"
    >
      <div class="dialog-container">
        <div slot="title" class="dialog-header">
          <div class="reason-title-left">
            <span class="fail-result-icon">
              <i class="iconfont guoran-shanchu"></i>
            </span>
            <span>失败原因</span>
          </div>
          <div class="title-right" @click="lookFailReason = false">
            <i class="iconfont guoran-shanchu"></i>
          </div>
        </div>
        <div class="title-t">
          <div class="title-left">
            <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
            <span>{{new Date(recordItem.createTime).Format('MM/dd hh:mm')}}</span>
          </div>
          <div class="title-right" :class="recordItem.inSending?'is-sending-class':''">
            应发送{{recordItem.totalNum}}人
            <span>,</span>
            <span>发送失败
              <span style="color: red">{{failNumber}}</span>
              人
            </span>
          </div>
        </div>
        <div class="reason-content">
          <span class="left">
            <template v-if="recordItem.attachment.msgtype ==='text'">
              <span v-html="recordItem.attachment.text.content"></span>
            </template>
            <template v-else>
              {{setAttachment}}
            </template>
          </span>
          <span class="right" @click="lookDetail">查看详情</span>
        </div>
        <div class="fail-reason-list">
          <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{background:'#F6F8FD',color:'#000000'}"
              :row-style="{color:'#616161'}"
          >
            <el-table-column
                label="姓名"
                width="100"
                align="center"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.detail.displayInfo">
                  <open-data v-if="scope.row.detail.displayInfo.wechatThirdRender" :type="scope.row.detail.displayInfo.type==='MEMBER'?'userName':'departmentName'" :openid="scope.row.detail.displayInfo.name"></open-data>
                  <span v-else>{{scope.row.detail.displayInfo.name}}</span>
                </template>
                <template v-else>--</template>
              </template>
            </el-table-column>
            <el-table-column
                label="失败原因">
              <template slot-scope="scope">
                <template v-if="scope.row.failedInfo && scope.row.failedInfo.message">
                  {{scope.row.failedInfo.message}}
                </template>
                <template v-else>其他</template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Preview from "@/views/fissionMarketing/components/preview";
import OpenData from "@/components/openData";
export default {
  name: "recordsItem",
  components: { OpenData, Preview },
  props:["recordItem","taskRow"],
  data(){
    return{
      personnelList:[],
      failPersonnelList:[],
      failNumber:0,
      successNumber:0,
      welcomeConfig:{
        attachmentList:[]
      },
      viewDetails:false,
      lookFailReason:false,
      lastId:"",
      failLastId:"",
      isHasNext:true, //发送成功
      failIsLast:true,  //发送失败
      tableData:[], //失败原因
      percentage:0,
      timers:null,
      inSending:{}
    }
  },
  computed:{
    setAttachment(){
      if (this.recordItem.attachment.msgtype ==='video'){
        return '【视频】'
      } else if (this.recordItem.attachment.msgtype ==='image'){
        return '【图片】'
      } else if (this.recordItem.attachment.msgtype === 'file'){
        return '【附件】'
      } else if (this.recordItem.attachment.msgtype === 'link'){
        return '【图文】'
      } else if (this.recordItem.attachment.msgtype === 'messagecard'){
        return '【消息卡片】'
      } else if (this.recordItem.attachment.msgtype === 'botcard'){
        return '【机器人交互卡片】'
      } else if (this.recordItem.attachment.msgtype === 'weathercard'){
        return '【天气卡片】'
      } else if (this.recordItem.attachment.msgtype === 'datacard'){
        return '【机器人数据卡片】'
      }
    }
  },
  methods:{
    lookDetail(){
      this.viewDetails = true;
      this.welcomeConfig.attachmentList = [this.recordItem.attachment]
    },
    //关闭详情时将所有都关闭
    closeDetail(type){
      if (type === 'close'){
        this.$emit('closeDetail')
      }
      this.viewDetails = false;
      this.lookFailReason = false;
    },
    //获取记录中接收人信息
    getPlayInfo(){
      let url = "/scrm-api/group-send-message-task-record/attachment-detail-receivers?taskId=" + this.taskRow.id + '&subRecordId=' + this.recordItem.id + '&success=true' + '&size=20';
      if (this.lastId){
        url += '&lastId='+this.lastId
      }
      this.FetchGet(url).then(res=>{
        console.debug('displayInfo',res)
        this.personnelList = this.personnelList.concat(res.data);
        this.isHasNext = res.hasNext;
        this.successNumber = res.total;
      })
    },
    //获取失败的记录接收人
    getFailInfo(){
      let failUrl = "/scrm-api/group-send-message-task-record/attachment-detail-receivers?taskId=" + this.taskRow.id + '&subRecordId=' + this.recordItem.id + '&success=false' + '&size=20';
      if (this.failLastId){
        failUrl += '&lastId=' + this.failLastId
      }
      this.FetchGet(failUrl).then(res=>{
        console.debug('failInfo',res)
        this.failPersonnelList = this.failPersonnelList.concat(res.data);
        this.failIsLast = res.hasNext;
        this.failNumber = res.total;
      })
    },
    //加载更多记录接收人
    loadMorePerson(type){
      if (type === 'success'){
        this.lastId = this.personnelList[this.personnelList.length - 1].id;
        this.getPlayInfo();
      } else {
        this.failLastId = this.failPersonnelList[this.failPersonnelList.length - 1].id
        this.getFailInfo();
      }
    },
    openFailReason(){
      if (this.recordItem.inSending){
        this.$message({
          message:"任务正在发送中",
          duration:2000
        })
        return
      }
      this.lookFailReason = true;
      this.getFailReason()
    },
    //获取失败原因
    getFailReason(){
      let url = "/scrm-api/group-send-message-task-record/attachment-detail-origin-request?taskId=" + this.taskRow.id + '&subRecordId=' + this.recordItem.id + '&success=false' + '&size=20';
      this.FetchGet(url).then(res=>{
        console.debug('fail reason',res)
        this.tableData = res.data;
      })
    },
    getAttachmentDetail(){
      let url = "/scrm-api/group-send-message-task-record/attachment-detail/" + this.taskRow.id + '/' + this.recordItem.id;
      this.FetchGet(url).then(res=>{
        this.recordItem.inSending = res.inSending;
        if (res.inSending){
          this.timers = setInterval(()=>{
            this.percentage += 20;
          },1000)
          setTimeout(()=>{
            this.getAttachmentDetail();
          },2000)
        }
        else {
          this.percentage = 100;
          clearInterval(this.timers);
          this.getPlayInfo();
          this.getFailInfo();
        }
      })
    }
  },
  mounted() {
    this.getPlayInfo();
    this.getFailInfo();
    if (this.recordItem.inSending){
      this.percentage = 10;
      this.getAttachmentDetail();
    }
  }
};
</script>

<style scoped lang="less">
.record-item{
  margin-bottom: 12px;
  .send-content{
    background: #F6F8FD;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 10px 12px;
    .left{
      max-width: 450px;
      display: -webkit-box;
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-align: left;
      p{
        span{
          max-width: 450px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .right{
      color: #366aff;
      cursor:pointer;
      font-size: 12px;
    }
  }
  .title{
    margin-bottom: 12px;
    .title-t{
      background: #F3F4F6;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-radius: 5px;
      .title-left{
        display: flex;
        align-items: center;
        i{
          font-size: 14px;
          color: #A0B6FF;
          margin-right: 6px;
        }
      }
      .is-sending-class{
        color: #366aff!important;
        .sending-text{
          margin-left: 6px;
        }
      }
    }
  }
  .send-result{
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 0px rgba(29,55,129,0.14);
    border-radius: 5px;
    padding: 12px;
    .result-item{
      .result-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .result-title-left{
          display: flex;
          align-items: center;
        }
        .result-title-right{
          color: #366aff;
          font-size: 12px;
          cursor:pointer;
        }
        .in-sending{
          color: #BDC4D1!important;
          cursor: default!important;
        }
        .result-icon{
          width: 18px;
          height: 18px;
          background: #04B051;
          border-radius: 50%;
          flex: none;
          display: flex;
          align-items: center;
          justify-content: center;
          i{
            color: #FFFFFF;
            font-size: 12px;
          }
        }
        .fail-result-icon{
          width: 18px;
          height: 18px;
          background: #FE5965;
          border-radius: 50%;
          display: flex;
          align-items:center;
          justify-content: center;
          i{
            color: #FFFFFF;
            font-size: 14px;
          }
        }
        .result-word{
          margin: 0 6px;
          font-weight: bold;
        }
        .send-number{
          padding: 2px 8px;
          background: #ECF1FF;
          border-radius: 10px;
          display:flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #366aff;
          font-weight: 400;
        }
      }
      .result-personnel{
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
        /*display: -webkit-box;
        word-break: break-all;
        text-overflow:ellipsis;
        overflow: hidden;
        white-space: pre-line;
        -webkit-box-orient: vertical;
        -webkit-line-clamp:3;*/
        .personnel-item{
          display: flex;
          align-items: center;
          margin: 4px 16px 4px 0;
          .sign{
            width: 6px;
            height: 6px;
            background: #D2D8E3;
            border-radius: 50%;
            flex: none;
            margin-right: 6px;
          }
          .name{
            color: #616161;
          }
        }

      }
      .lodeMore{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #A1B9FF;
        border-radius: 15px;
        margin: 0 auto 12px auto;
        font-size: 12px;
        color: #366aff;
        cursor: pointer;
        i{
          font-size: 12px;
          margin-right: 4px;
        }
      }
    }
    .success-result{
      border-bottom: 1px dashed #E0E6F7;
    }
    .fail-result{
      margin-top: 12px;
    }
  }
}
.dialog-container{
  .dialog-header{
    height: 45px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: #F6F8FD;
    font-size: 16px;
    margin-bottom: 20px;
    color: #303133;
    .title-left{
      cursor: pointer;
      i{
        margin-right: 12px;
      }
    }
    .title-right{
      i{
        font-size: 18px;
      }
    }
  }
  .view-detail-content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reason-title-left{
    display: flex;
    align-items: center;
    .fail-result-icon{
      width: 18px;
      height: 18px;
      background: #FE5965;
      border-radius: 50%;
      display: flex;
      align-items:center;
      justify-content: center;
      margin-right: 6px;
      i{
        color: #FFFFFF;
        font-size: 14px;
      }
    }
  }
  .reason-content{
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 0px rgba(29,55,129,0.14);
    border-radius: 5px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
    .left{
      max-width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .right{
      color: #366aff;
      cursor:pointer;
      font-size: 12px;
    }
  }
  .fail-reason-list{
    margin: 0 20px;
  }
  .title-t{
    background: #F3F4F6;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 5px;
    margin: 0 20px;
    .title-left{
      display: flex;
      align-items: center;
      i{
        font-size: 14px;
        color: #A0B6FF;
        margin-right: 6px;
      }
    }
    .is-sending-class{
      color: #366aff!important;
      .sending-text{
        margin-left: 6px;
      }
    }
  }
}

</style>