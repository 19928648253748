<template>
    <div id="source-box">
        <div class="timeQuickSelection">
            <el-select size="small" v-model="conditionCell.relation">
                <el-option
                    v-for="item in operationrelationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>
        <div class="suorceSelection">
            <el-select
                size="small"
                @change="suorceSelectionChange"
                v-model="conditionCell.valueType"
            >
                <el-option
                    v-for="item in sourceOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>
        <div
            v-if="conditionCell.valueType != 'ADD_MANULLY'"
            class="suorceValueSelection"
        >
            <el-select
                v-model="conditionCell.value"
                multiple
                size="small"
                filterable
                remote
                reserve-keyword
                placeholder="请选择或输入（非必填）"
                @focus="remoteMethodFocus($events, conditionCell.valueType)"
                :remote-method="remoteMethod"
                :loading="loading"
            >
                <el-option
                    v-for="item in mOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
import { options } from "./autoTagOptions";
export default {
    props: ["conditionCell", "index", "indexCell"],
    data() {
        return {
            operationrelationOptions: options.operationrelationOptionsT,
            sourceOptions: options.sourceOptions,
            mOptions: [],
            loading: false,
            list: [],
        };
    },
    methods: {
        remoteMethod(query) {
            this.loading = true;
            let url = this.requestUrl.autoTag.autoTagSourceCondition;
            let param = {
                keyword: query.trim(),
                source: this.conditionCell.valueType,
            };
            this.FetchPost(url, param)
                .then((res) => {
                    this.loading = false;
                    this.mOptions = res;
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        remoteMethodFocus(e, valueType){
            // console.log(e, valueType);
            // this.mOptions = [];
            // this.remoteMethod("");
        },
        suorceSelectionChange() {
            this.conditionCell.value = [];
            this.mOptions = [];
            this.remoteMethod("");
        },
        // 用于回显
        getAllValOptions(valueType) {
            this.FetchPost(this.requestUrl.autoTag.autoTagSourceCondition, {
                keyword: "",
                source: valueType,
            })
                .then((res) => {
                    this.mOptions = [...res];
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
    },
    mounted() {
        this.getAllValOptions(this.conditionCell.valueType);
    },
};
</script>
<style lang="less" scoped>
#source-box {
    display: flex;
    justify-content: flex-start;
    .suorceSelection {
        margin-left: 6px;
        width: 150px;
        flex: none;
    }
    .suorceValueSelection {
        margin-left: 6px;
        width: 270px;
        flex: none;
        .el-select {
            width: 100%;
        }
    }
}
</style>