<template>
    <div id="sendContent">
        <div class="send-content-left">
            <send-content-component
                    @componentCheck="componentCheck"
            ></send-content-component>
        </div>
        <div class="send-content-center">
            <div class="send-content-center-container" id="send-content-center-container">
                <div class="send-content-card-item"
                     v-for="(item,index) in welcomeConfig.attachmentList"
                     :key="index"
                >
                    <div class="send-content-card-header">
                        <div class="header-left">{{item.msgtype | cardName}}</div>
                        <div class="header-right">
                            <!--每个组件下暂时隐掉从素材中添加，不确定是覆盖还是应该增加-->
                            <span class="cursorPointer set-padding"
                                  @click="selectFromMaterial(item)"
                                  v-if="(item.msgtype==='image'||item.msgtype==='video'||
                              item.msgtype==='link'||item.msgtype==='file'||item.msgtype==='text')&&false"
                            ><i class="iconfont guoran-a-16-13"></i>从素材中心添加</span>
                            <span class="set-padding cursorPointer"
                                  @click="insertVariables(currentEditorList[item.msgtype].currentEditor,item)"
                                  v-if="item.msgtype==='text'||
                              item.msgtype==='messagecard'||
                              item.msgtype==='weathercard'||
                              item.msgtype==='datacard'"
                            >
                            <i class="iconfont guoran-charubianliang"></i>插入变量</span>
                            <span class="set-padding-right my-handle" @mouseenter="resortContent"><i
                                    class="iconfont guoran-a-16-10"></i></span>
                            <span @click="deleteCardItem(item,index)"><i class="iconfont guoran-a-16-09"></i></span>
                        </div>
                    </div>
                    <div class="card-content">
                        <ckeditor
                                :editor="ckeditor.editor"
                                v-model="item.text.content"
                                :config="editorTextConfig"
                                @focus="(zh,editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                @ready="(editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                v-if="item.msgtype==='text'"
                        ></ckeditor>
                        <div v-else-if="item.msgtype==='image'" class="upload-class">
                            <el-upload
                                    class="upload-demo"
                                    :class="item.image.url?'hide-upload':''"
                                    action=""
                                    list-type="picture-card"
                                    limit="1"
                                    :ref="'elUpload'+index"
                                    :http-request="aliyunOssRequestImage"
                                    :file-list="item.image.extInfo.fileList"
                                    accept=".jpg,.png"
                                    :before-upload="((file)=>{
                                        beforeUpload(file,index)
                                    })"
                                    :on-remove="((file,fileList)=>{
                                        onRemove(item,index)
                                })"
                                    :on-success="((res,file,fileList)=>{
                                    onSuccess(res,file,fileList,item)
                                })"
                                    :on-preview="onPreview">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <div class="upload-tip">
                                请上传
                                <span>{{channelType==="DING_TALK"?"10M":"2M"}}</span>
                                以内的图片，支持 .jpg .png格式的图片
                            </div>
                        </div>
                        <div v-else-if="item.msgtype==='video'" class="upload-class">
                            <!--视频展示图-->
                            <div class="picture-item-image"
                                 v-if="item.video.url">
                                <el-dialog
                                        title :visible.sync="dialogPlay"
                                        width="40%"
                                        :close-on-click-modal="false"
                                        @close="closeDialog"
                                        append-to-body
                                >
                                    <video :src="videoUrl"
                                           controls
                                           autoplay
                                           class="video"
                                           height="500px"
                                           width="100%"></video>
                                </el-dialog>
                                <span>
                                     <em class="el-icon-video-play"
                                         style="font-size:36px;color:#366AFF;cursor: pointer;"
                                         @click="playVideo(item.video.url)"></em>
                                </span>
                                <div class="delete-video-icon"
                                     @click="onRemove(item,index)"
                                >
                                    <i class="el-icon-delete"></i>
                                </div>

                            </div>
                            <el-upload
                                    class="upload-demo"
                                    :class="item.video.url?'hide-upload':''"
                                    action=""
                                    list-type="picture-card"
                                    limit="1"
                                    :ref="'elUpload'+index"
                                    accept=".mp4"
                                    :show-file-list="false"
                                    :http-request="aliyunOssRequestVideo"
                                    :before-upload="((file)=>{
                                        beforeUpload(file,index)
                                    })"
                                    :on-remove="((file,fileList)=>{
                                    onRemove(item,index)
                                })"
                                    :on-success="((res,file,fileList)=>{
                                    onSuccess(res,file,fileList,item)
                                })"
                                    :on-preview="onPreview">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <div class="upload-tip">
                                请上传10M以内的视频，支持.MP4格式的视频
                            </div>
                        </div>
                        <div v-else-if="item.msgtype==='file'" class="upload-class">
                            <!--文件展示图-->
                            <div class="picture-item-image" v-if="item.file.url">
                                <el-image
                                        style="width: auto;height:120px;"
                                        fit="fill"
                                        src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/scrm/preview-img.jpg"></el-image>
                                <span class="delete-video-icon"
                                      @click="onRemove(item,index)"
                                >
                                     <i class="el-icon-delete"></i>
                                 </span>
                            </div>
                            <el-upload
                                    class="upload-demo"
                                    :class="item.file.url?'hide-upload':''"
                                    action=""
                                    :ref="'elUpload'+index"
                                    list-type="picture-card"
                                    limit="1"
                                    :show-file-list="false"
                                    :http-request="aliyunOssRequestFile"
                                    :before-upload="((file)=>{
                                        beforeUpload(file,index)
                                    })"
                                    :on-remove="((file,fileList)=>{
                                    onRemove(item,index)
                                })"
                                    :on-success="((res,file,fileList)=>{
                                    onSuccess(res,file,fileList,item)
                                })"
                                    :on-preview="onPreview">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <div class="upload-tip">
                                请上传
                                <span>{{channelType==="DING_TALK"?"10M":"20M"}}</span>
                                以内文件
                            </div>
                        </div>
                        <div v-else-if="item.msgtype==='link'" class="link-class">
                            <el-form label-position="top">
                                <el-form-item label="标题">
                                <span slot="label" class="link-label">
                                    <span class="label-left">
                                        <span>标题</span>
                                        <span class="red">*</span>
                                    </span>
                                    <span class="label-right"
                                          @click="insertVariables(currentEditorList[item.msgtype].currentEditor,item)">
                                         <i class="iconfont guoran-charubianliang"></i>插入变量
                                    </span>
                                </span>
                                    <div class="label-content">
                                        <ckeditor
                                                :editor="ckeditor.editor"
                                                v-model="item.link.title"
                                                :config="editorConfig"
                                                @focus="(zh,editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                                @ready="(editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                        ></ckeditor>
                                    </div>
                                    <!--<el-input v-model="item.title" placeholder="请输入标题"></el-input>-->
                                </el-form-item>
                                <el-form-item label="正文">
                                <span slot="label" class="link-label">
                                    <span class="label-left">
                                        <span>正文</span>
                                        <span class="red">*</span>
                                    </span>
                                    <span class="label-right"
                                          @click="insertVariables(currentEditorList[item.msgtype].currentEditor,item)">
                                         <i class="iconfont guoran-charubianliang"></i>插入变量
                                    </span>
                                </span>
                                    <div class="text-content">
                                        <ckeditor
                                                :editor="ckeditor.editor"
                                                v-model="item.link.content"
                                                :config="editorConfigTool"
                                                @focus="(zh,editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                                @ready="(editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                        ></ckeditor>
                                    </div>

                                </el-form-item>
                                <el-form-item label="封面">
                                <span slot="label">
                                    <span>封面</span>
                                    <span class="red">*</span>
                                </span>
                                    <div class="upload-class">
                                        <el-upload
                                                class="upload-demo"
                                                :class="item.link.picurl?'hide-upload':''"
                                                action=""
                                                list-type="picture-card"
                                                limit="1"
                                                :ref="'elUpload'+index"
                                                accept=".jpg,.png"
                                                :file-list="item.link.extInfo.fileList"
                                                :http-request="aliyunOssRequest"
                                                :on-remove="((file,fileList)=>{
                                                    onRemove(item,index)
                                                })"
                                                :before-upload="((file)=>{
                                                    beforeUpload(file,index)
                                                })"
                                                :on-success="((res,file,fileList)=>{
                                                onSuccess(res,file,fileList,item)
                                            })"
                                                :on-preview="onPreview">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <div class="upload-tip">
                                            请上传2M以内的图片，支持 .jpg .png格式的图片
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="摘要">
                                <span slot="label" class="link-label">
                                    <span class="label-left">
                                        <span>摘要</span>
                                        <span class="red">*</span>
                                    </span>
                                    <span class="label-right"
                                          @click="insertVariables(currentEditorList[item.msgtype].currentEditor,item)">
                                         <i class="iconfont guoran-charubianliang"></i>插入变量
                                    </span>
                                </span>
                                    <div class="label-content">
                                        <ckeditor
                                                :editor="ckeditor.editor"
                                                v-model="item.link.desc"
                                                :config="editorConfig"
                                                @focus="(zh,editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                                @ready="(editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                        ></ckeditor>
                                    </div>
                                    <!--<el-input placeholder="请输入" v-model="item.desc"></el-input>-->
                                </el-form-item>
                                <el-form-item label="作者">
                                    <el-input placeholder="请输入" v-model="item.link.author"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div v-else-if="item.msgtype==='external_link'" class="link-class">
                            <el-form label-position="top">
                                <el-form-item label="标题">
                                <span slot="label" class="link-label">
                                    <span class="label-left">
                                        <span>标题</span>
                                        <span class="red">*</span>
                                    </span>
                                    <span class="label-right"
                                          @click="insertVariables(currentEditorList.externalLink.currentEditor,item)">
                                         <i class="iconfont guoran-charubianliang"></i>插入变量
                                    </span>
                                </span>
                                    <div class="label-content">
                                        <ckeditor
                                                :editor="ckeditor.editor"
                                                v-model="item.externalLink.title"
                                                :config="editorConfig"
                                                @focus="(zh,editor)=>currentEditorList.externalLink.currentEditor=editor"
                                                @ready="(editor)=>currentEditorList.externalLink.currentEditor=editor"
                                        ></ckeditor>
                                    </div>
                                    <!--<el-input v-model="item.title" placeholder="请输入标题"></el-input>-->
                                </el-form-item>
                                <el-form-item label="消息链接">
                                <span slot="label" class="link-label">
                                    <span class="label-left">
                                        <span>消息链接</span>
                                        <span class="red">*</span>
                                    </span>
                                </span>
                                    <div class="label-content">
                                        <el-input v-model="item.externalLink.url" placeholder="请输入合法链接"></el-input>
                                    </div>

                                </el-form-item>
                                <el-form-item label="封面">
                                <span slot="label">
                                    <span>封面</span>
                                    <span class="red">*</span>
                                </span>
                                    <div class="upload-class">
                                        <el-upload
                                                class="upload-demo"
                                                :class="item.externalLink.picurl?'hide-upload':''"
                                                action=""
                                                list-type="picture-card"
                                                limit="1"
                                                accept=".jpg,.png"
                                                :ref="'elUpload'+index"
                                                :file-list="item.externalLink.extInfo.fileList"
                                                :http-request="aliyunOssRequest"
                                                :on-remove="((file,fileList)=>{
                                                onRemove(item,index)
                                            })"
                                                :before-upload="((file)=>{
                                                    beforeUpload(file,index)
                                                })"
                                                :on-success="((res,file,fileList)=>{
                                                onSuccess(res,file,fileList,item)
                                            })"
                                                :on-preview="onPreview">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <div class="upload-tip">
                                            请上传2M以内的图片，支持 .jpg .png格式的图片
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="摘要">
                                <span slot="label" class="link-label">
                                    <span class="label-left">
                                        <span>摘要</span>
                                        <span class="red">*</span>
                                    </span>
                                    <span class="label-right"
                                          @click="insertVariables(currentEditorList.externalLink.currentEditor,item)">
                                         <i class="iconfont guoran-charubianliang"></i>插入变量
                                    </span>
                                </span>
                                    <div class="label-content">
                                        <ckeditor
                                                :editor="ckeditor.editor"
                                                v-model="item.externalLink.desc"
                                                :config="editorConfig"
                                                @focus="(zh,editor)=>currentEditorList.externalLink.currentEditor=editor"
                                                @ready="(editor)=>currentEditorList.externalLink.currentEditor=editor"
                                        ></ckeditor>
                                    </div>
                                    <!--<el-input placeholder="请输入" v-model="item.desc"></el-input>-->
                                </el-form-item>
                            </el-form>
                        </div>
                        <div v-else-if="item.msgtype==='messagecard'||
                        item.msgtype==='weathercard'||
                        item.msgtype==='datacard'"
                             class="message-card">
                            <ckeditor
                                    :editor="ckeditor.editor"
                                    v-model="item[item.msgtype].content"
                                    :config="editorConfig"
                                    @focus="(zh,editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                    @ready="(editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                            ></ckeditor>
                            <div class="button-list" :id="'button-list'+index">
                                <div class="button-item"
                                     v-for="(btn,indexs) in item[item.msgtype].btnList"
                                     :key="indexs"
                                >
                                    <div class="button-left">
                                        <span class="my-handle-btn"
                                              @mouseenter="resortBtn(item[item.msgtype],index)">
                                            <i class="iconfont guoran-a-16-10"></i>
                                        </span>
                                        <div class="button-name">
                                            <div class="label">按钮名称</div>
                                            <el-input v-model="btn.name" placeholder="请输入" size="small"
                                                      style="width: 90%"></el-input>
                                        </div>
                                        <div class="button-link">
                                            <div class="label">跳转地址</div>
                                            <el-input v-model="btn.link.url" placeholder="请输入" size="small"
                                                      style="width: 100%"></el-input>
                                        </div>

                                    </div>
                                    <div class="button-right" @click="deleteBtn(item[item.msgtype],indexs)">
                                        <i class="iconfont guoran-a-16-09"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="add-button" @click="addMessageBtn(item[item.msgtype])">
                                <span class="add-icon">
                                    <i class="iconfont guoran-a-16-13"></i>
                                </span>
                                <span>添加按钮</span>
                            </div>
                        </div>
                        <div v-else-if="item.msgtype==='botcard'" class="bot-card">
                            <el-form label-position="top">
                                <el-form-item label="标题">
                                <span slot="label" class="link-label">
                                    <span class="label-left">
                                        <span>标题</span>
                                        <span class="red">*</span>
                                    </span>
                                    <span class="label-right"
                                          @click="insertVariables(currentEditorList[item.msgtype].currentEditor,item)">
                                         <i class="iconfont guoran-charubianliang"></i>插入变量
                                    </span>
                                </span>
                                    <div class="label-content">
                                        <ckeditor
                                                :editor="ckeditor.editor"
                                                v-model="item.botcard.title"
                                                :config="editorConfig"
                                                @focus="(zh,editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                                @ready="(editor)=>currentEditorList[item.msgtype].currentEditor=editor"
                                        ></ckeditor>
                                    </div>
                                </el-form-item>
                                <el-form-item label="正文">
                                <span slot="label">
                                    <span>摘要</span>
                                    <span class="red">*</span>
                                </span>
                                    <el-input v-model="item.botcard.desc" placeholder="请输入摘要"></el-input>
                                </el-form-item>
                                <el-form-item label="封面">
                                <span slot="label">
                                    <span>封面</span>
                                    <span class="red">*</span>
                                </span>
                                    <div class="upload-class">
                                        <el-upload
                                                class="upload-demo"
                                                :class="item.botcard.picurl?'hide-upload':''"
                                                action=""
                                                list-type="picture-card"
                                                limit="1"
                                                accept=".jpg,.png"
                                                :ref="'elUpload'+index"
                                                :file-list="item.botcard.extInfo.fileList"
                                                :http-request="aliyunOssRequest"
                                                :on-remove="((file,fileList)=>{
                                                onRemove(item,index)
                                            })"
                                                :before-upload="((file)=>{
                                                    beforeUpload(file,index)
                                                })"
                                                :on-success="((res,file,fileList)=>{
                                                onSuccess(res,file,fileList,item)
                                            })"
                                                :on-preview="onPreview">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <div class="upload-tip">
                                            请上传2M以内的图片，支持 .jpg .png格式的图片
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="关联应用/机器人">
                                 <span slot="label">
                                    <span>关联应用/机器人</span>
                                    <span class="red">*</span>
                                </span>
                                    <el-select
                                            v-model="item.botcard.apiKey"
                                            placeholder="选择交互对话机器人"
                                            style="width: 100%;"
                                            clearable
                                            @change="getIntent"
                                    >
                                        <el-option
                                                :class="item.botcard.apiKey===bot.apiKey?'send-range-item':''"
                                                v-for="bot in botList"
                                                :key="bot.apiKey"
                                                :value="bot.apiKey"
                                                :label="bot.name"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="关联意图">
                                 <span slot="label">
                                    <span>关联意图</span>
                                    <span class="red">*</span>
                                </span>
                                    <el-cascader
                                            placeholder="选择意图"
                                            style="width: 100%"
                                            v-model="item.botcard.initIntentId"
                                            :options="intentList[item.botcard.apiKey]"
                                            :props="defaultProps"
                                            :key="isKey"
                                            clearable
                                            :show-all-levels="false"
                                            ref="cascaderIntent"
                                            popper-class="task_cascaderIntent"
                                    >

                                    </el-cascader>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showVariable" class="variableDialog">
                <insert-variable :variableList="variableList"
                                 @closeVariable="closeVariable"
                                 @selectVariable="selectVariable"
                ></insert-variable>
            </div>
        </div>
        <div v-if="!isShowTest && !isShowHeathly" class="send-content-right">
            <preview :welcome-config="welcomeConfig"></preview>
        </div>

        <popup v-if="materialCenter"
               @closeEvent="materialCenter=false"
               class="selectMaterial"
        >
            <div slot="popup-name" class="popup-name">选择素材</div>
            <div slot="popup-con">
                <MaterialSelection
                        ref="materialSelect"
                        :source="'massSend'"
                        :materialType="materialType"
                        @saveSelectedMaterial="saveSelectedMaterial"
                ></MaterialSelection>
            </div>
            <div slot="dialog-footer" class="member-footer">
                <el-button @click="materialCenter = false" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="selectMaterial" size="small">保 存</el-button>
            </div>
        </popup>
        <popup v-if="datePopup"
               @closeEvent="datePopup = false"
               class="setDateRange"
        >
            <div slot="popup-name" class="popup-name">自定义日期范围</div>
            <div slot="popup-tip" class="popup-tip">
                设置动态的日期范围
            </div>
            <div slot="popup-con">
                <el-form label-position="top" label-width="120px">
                    <el-form-item label="变量名称">
                        <el-input v-model="dateRange.name" placeholder="请输入变量名称"></el-input>
                    </el-form-item>
                    <el-form-item label="配置日期范围">
                        <div class="date-range">
                            <el-select v-model="dateRange.dateBeforeType"
                                       placeholder="请选择"
                                       style="margin-right: 10px"
                                       @change="changeDateRangeOne"
                            >
                                <el-option
                                        v-for="item in dateRange.dateRangeOne"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-input v-model="dateRange.dateBeforeTime"
                                      placeholder="请输入"
                                      style="width: 30%"
                                      v-if="dateRange.dateBeforeType!=='plus(0)'"
                                      @input="getVariableName"
                                      type="number"
                                      min="1"
                            ></el-input>
                            <span style="padding-left: 10px" v-if="dateRange.dateBeforeType!=='plus(0)'">天</span>
                            <span style="padding: 0 10px"> ~ </span>
                            <el-select v-model="dateRange.dateAfterType"
                                       placeholder="请选择"
                                       style="margin-right: 10px"
                                       @change="changeDateRangeTwo"
                            >
                                <el-option
                                        v-for="item in dateRange.dateRangeTwo"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-input v-model="dateRange.dateAfterTime"
                                      placeholder="请输入"
                                      style="width: 30%"
                                      @change="dateAfterTimeInput"
                                      type="number"
                                      min="1"
                                      v-if="dateRange.dateAfterType!=='plus(0)'"
                            ></el-input>
                            <span style="padding-left: 10px" v-if="dateRange.dateAfterType!=='plus(0)'">天</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="dialog-footer" class="member-footer">
                <el-button @click="datePopup = false" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="saveCustomDate" size="small">保 存</el-button>
            </div>
        </popup>
        <popup v-if="receptionPopup"
               @closeEvent="receptionPopup = false"
               class="setDateRange"
        >
            <div slot="popup-name" class="popup-name">{{receptionNumber.title}}</div>
            <div slot="popup-tip" class="popup-tip">
                设置应用或机器人某日期/某日期范围的{{receptionNumber.title}}
            </div>
            <div slot="popup-con">
                <el-form label-position="top" label-width="120px">
                    <el-form-item label="变量名称">
                        <el-input v-model="receptionNumber.name" placeholder="请输入变量名称"></el-input>
                    </el-form-item>
                    <el-form-item label="选择统计时间">
                        <div class="date-range">
                            <el-select v-model="receptionNumber.receptionTime"
                                       placeholder="请选择"
                                       style="width: 100%"
                                       @change="receptionTimeChange"
                            >
                                <el-option
                                        v-for="item in receptionNumber.receptionTimeList"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="date-range-select" v-if="receptionNumber.receptionTime==='custom'">
                            <div class="date-range">
                                <el-select v-model="receptionNumber.dateBeforeType"
                                           placeholder="请选择"
                                           style="margin-right: 10px"
                                           @change="receptionDateType"
                                >
                                    <el-option
                                            v-for="item in receptionNumber.dateRangeOne"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-input v-model="receptionNumber.dateBeforeTime"
                                          placeholder="请输入"
                                          style="width: 30%"
                                          v-if="receptionNumber.dateBeforeType!=='plus(0)'"
                                          type="number"
                                          min="1"
                                ></el-input>
                                <span style="padding-left: 10px"
                                      v-if="receptionNumber.dateBeforeType!=='plus(0)'">天</span>
                                <span style="padding: 0 10px"> ~ </span>
                                <el-select v-model="receptionNumber.dateAfterType"
                                           placeholder="请选择"
                                           style="margin-right: 10px"
                                           @change="receptionNumber.dateAfterTime = ''"
                                >
                                    <el-option
                                            v-for="item in receptionNumber.dateRangeTwo"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-input v-model="receptionNumber.dateAfterTime"
                                          placeholder="请输入"
                                          style="width: 30%"
                                          type="number"
                                          min="1"
                                          @change="recepyionDateAfterTimeInput"
                                          v-if="receptionNumber.dateAfterType!=='plus(0)'"
                                ></el-input>
                                <span style="padding-left: 10px"
                                      v-if="receptionNumber.dateAfterType!=='plus(0)'">天</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="选择统计范围">
                        <div class="date-range">
                            <el-select
                                    filterable
                                    v-model="receptionNumber.receptionRange"
                                    placeholder="请选择"
                                    style="width: 100%"
                                    @change="receptionChange"
                            >
                                <el-option-group
                                        v-for="group in receptionNumber.cOptions"
                                        :key="group.label"
                                        :label="group.label"
                                >
                                    <el-option
                                            v-for="item in group.options"
                                            :key="item.type + ',' + item.scope"
                                            :label="item.name"
                                            :value="item.type + ',' + item.scope"
                                    >
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="dialog-footer" class="member-footer">
                <el-button @click="receptionPopup = false" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="saveReception" size="small">保 存</el-button>
            </div>
        </popup>
        <popup v-if="selectCity" @closeEvent="selectCity = false">
            <div slot="popup-name" class="popup-name">选择城市</div>
            <div slot="popup-con">
                <el-dropdown
                        trigger="click"
                        id="el-dropdown-work-order"
                        @visible-change="
                      elDropdownvisibleChange
                  "
                        :hide-on-click="false"
                >
                  <span
                          :class="[
                          'el-dropdown-link',
                          'el-dropdown-link-workorder',
                          workOrderSelectIng
                              ? 'active-el-dropdown-link'
                              : '',
                      ]"
                  >
                      <span
                              v-if="detailCity"
                              class="
                              el-dropdown-work-order-content
                          "
                      >
                          {{detailCity.name}}
                      </span>
                      <span
                              v-else
                              class="el-dropdown-placeholder"
                      >请选择城市</span
                      >
                      <span class="el-icon">
                          <em
                                  v-show="!workOrderSelectIng"
                                  class="el-icon-arrow-down"
                          ></em>
                          <em
                                  v-show="workOrderSelectIng"
                                  class="el-icon-arrow-up"
                          ></em>
                      </span>
                  </span>
                    <el-dropdown-menu
                            id="work-order-dropdown-person"
                            slot="dropdown"
                    >
                        <el-dropdown-item
                                class="work-order-dropdown-item-person"
                        >
                            <div style="height: 300px;overflow: auto;">
                                <el-tree
                                        :data="cityList"
                                        node-key="id"
                                        :default-expand-all="false"
                                        :expand-on-click-node="false"
                                        ref="cityTree"
                                        @node-click="treeNodeClick"
                                        check-strictly
                                        :props="typeTreeDefaultProps"
                                >
                                     <span class="custom-tree-node" slot-scope="{ node, data }">
                                        <span class="custom-tree-node-label">{{ data.name }}</span>
                                         <span v-if="data.checked"><i class="el-icon-check"></i></span>
                                     </span>
                                </el-tree>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div slot="dialog-footer" class="member-footer">
                <el-button @click="selectCity = false" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="saveCity" size="small">保 存</el-button>
            </div>
        </popup>
        <el-dialog :visible.sync="dialogVisible" custom-class="look-image">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import SendContentComponent from "./sendContentComponent";
    import CKEDITOR from "ckeditor";
    import { MyCustomUploadAdapterPlugin } from "./ckeditor";
    import { ossConfig, upload } from "../utils/AliyunlssUtil";
    import InsertVariable from "./insertVariable";
    import Sortable from "sortablejs";
    import Popup from "./popup";
    import MaterialSelection from "./materialSelection";
    import { redirectUri } from "../../../const/redirectUri";
    import Dialogue from "./dialogue";
    import Preview from "./preview";
    import { handleVariables } from "../utils/variables";

    export default {
        name: "sendContent",
        data() {
            return {
                isShowTest:false,
                contentList: [],
                currentEditor: null,
                currentEditorList: {
                    messagecard: { currentEditor: null },
                    weathercard: { currentEditor: null },
                    datacard: { currentEditor: null },
                    text: { currentEditor: null },
                    link: { currentEditor: null, currentEditorLinkContent: null, currentEditorLinkTitle: null },
                    botcard: { currentEditor: null },
                    externalLink: { currentEditor: null },
                },
                currentEditorLinkTitle: null,
                currentEditorLinkContent: null,
                currentEditorBotTitle: null,
                ckeditor: {
                    editor: CKEDITOR.ClassicEditor,

                },
                editorConfig: {
                    toolbar: [],
                    askPluginListener: [
                        {
                            event: "PLACEHOLDER",
                            process: (data, callback) => {
                                console.debug("editor call back", data, callback);
                            },
                        },
                    ], // 注册监听
                },
              editorTextConfig:{
                toolbar: ["link"],
                askPluginListener: [
                  {
                    event: "PLACEHOLDER",
                    process: (data, callback) => {
                      console.debug("editor call back", data, callback);
                    },
                  },
                ], // 注册监听
              },
                editorConfigTool: {
                    extraPlugins: [MyCustomUploadAdapterPlugin],
                    toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "imageUpload",
                        "insertTable",
                        "mediaEmbed",
                        "Undo",
                        "Redo",

                    ],
                    askPluginListener: [
                        {
                            event: "PLACEHOLDER",
                            process: (data, callback) => {
                                console.debug("editor call back", data, callback);
                            },
                        },
                    ], // 注册监听
                },
                showVariable: false,
                variableList: [],
                intentList: {},
                isKey: 1,
                defaultProps: {
                    label: "name",
                    value: "id",
                    emitPath: false,
                    expandTrigger: "hover",
                },
                materialCenter: false,
                dialogVisible: false,
                dialogImageUrl: "",
                materialType: "",
                material: {},
                checkCurrentEditor: null,
                variablePlaceholder: "插入变量",
                videoUrl: "",
                dialogPlay: false,
                datePopup: false,
                dateRange: {
                    name: "当前日期~当前日期后7天",
                    dateBeforeType: "plus(0)",
                    dateAfterType: "plus",
                    dateBeforeTime: "",
                    dateAfterTime: "7",
                    dateRangeOne: [{ name: "当前日期", value: "plus(0)" }, {
                        name: "当前日期前",
                        value: "minus",
                    }, { name: "当前日期后", value: "plus" }],
                    dateRangeTwo: [{ name: "当前日期前", value: "minus" }, { name: "当前日期后", value: "plus" }],
                },

                receptionPopup: false,
                receptionNumber: {
                    name: "",
                    receptionTime: "",
                    receptionTimeDate: "",
                    receptionRange: "",
                    receptionTimeList: [
                        {
                            name: "今天",
                            value: "${bot.statistics(scopeType,scope,date.today(),date.today()).reception_num()}",
                        },
                        {
                            name: "昨天",
                            value: "${bot.statistics(scopeType,scope,date.today().minus(1),date.today().minus(1)).reception_num()}",
                        },
                        {
                            name: "前天",
                            value: "${bot.statistics(scopeType,scope,date.today().minus(2),date.today().minus(2)).reception_num()}",
                        },
                        {
                            name: "本周",
                            value: "${bot.statistics(scopeType,scope,date.this_week().monday(),date.this_week().sunday()).reception_num()}",
                        },
                        {
                            name: "上周",
                            value: "${bot.statistics(scopeType,scope,date.this_week().minus(1).monday(),date.this_week().minus(1).sunday()).reception_num()}",
                        },
                        {
                            name: "本月",
                            value: "${bot.statistics(scopeType,scope,date.this_month().firstday(),date.this_month().lastday()).reception_num()}",
                        },
                        {
                            name: "上月",
                            value: "${bot.statistics(scopeType,scope,date.this_month().minus(1).firstday(),date.this_month().minus(1).lastday()).reception_num()}",
                        },
                        {
                            name: "前7天",
                            value: "${bot.statistics(scopeType,scope,date.today().minus(7),date.today()).reception_num()}",
                        },
                        {
                            name: "前30天",
                            value: "${bot.statistics(scopeType,scope,date.today().minus(30),date.today()).reception_num()}",
                        },
                        {
                            name: "自定义日期范围",
                            value: "custom",
                        },
                    ],
                    cOptions: [],
                    title: "总接待人数",
                    dateBeforeType: "minus",
                    dateAfterType: "plus(0)",
                    dateBeforeTime: "",
                    dateAfterTime: "",
                    dateRangeOne: [{ name: "当前日期", value: "plus(0)" }, {
                        name: "当前日期前",
                        value: "minus",
                    }],
                    dateRangeTwo: [{ name: "当前日期", value: "plus(0)" }],
                },
                scopeList: [],
                cityList: [],
                typeTreeDefaultProps: {
                    children: "children",
                    label: "name",
                },
                workOrderSelectIng: false,
                selectCity: false,
                detailCity: "",
                variableType: "",
                variableDetail: null,
                statisticsRange: {
                    name: "",
                    timeName: "",
                },
                uploadIndex: 0,
                isShowHeathly:false
            };
        },
        props: ["botList", "welcomeConfig", "channelType"],
        components: { Preview, Dialogue, MaterialSelection, Popup, InsertVariable, SendContentComponent },
        filters: {
            cardName(value) {
                if (value === "link") {
                    return "图文";
                } else if (value === "image") {
                    return "图片";
                } else if (value === "video") {
                    return "视频";
                } else if (value === "file") {
                    return "文件";
                } else if (value === "messagecard") {
                    return "消息卡片";
                } else if (value === "botcard") {
                    return "机器人交互卡片";
                } else if (value === "weathercard") {
                    return "天气卡片";
                } else if (value === "datacard") {
                    return "机器人数据卡片";
                } else if (value === "text") {
                    return "文本";
                } else if (value === "external_link") {
                    return "外链图文";
                }
            },
        },
        methods: {
            initIntent() {
                setTimeout(() => {
                    this.welcomeConfig.attachmentList.forEach(item => {
                        console.debug("item.msgtype", item.msgtype);
                        if (item.msgtype === "botcard") {
                            this.getIntent(item[item.msgtype].apiKey);
                        }
                    });
                }, 300);

            },
            playVideo(url) {
                this.dialogPlay = true;
                this.videoUrl = url;
            },
            closeDialog() {
                this.videoUrl = ""; //清空数据 关闭视频播放
            },
            //发送内容排序
            resortContent() {
                let _this = this;
                let el = document.getElementById("send-content-center-container");
                var Sortables = new Sortable(el, {
                    animation: 200,
                    easing: "cubic-bezier(1, 0, 0, 1)",
                    handle: ".my-handle",
                    onUpdate: function (event) {
                        let newIndex = event.newIndex,
                            oldIndex = event.oldIndex,
                            $li = el.children[newIndex],
                            $oldLi = el.children[oldIndex];
                        // 先删除移动的节点
                        el.removeChild($li);
                        // 再插入移动的节点到原有节点，还原了移动的操作
                        if (newIndex > oldIndex) {
                            el.insertBefore($li, $oldLi);
                        } else {
                            el.insertBefore($li, $oldLi.nextSibling);
                        }
                        let items = _this.welcomeConfig.attachmentList.splice(oldIndex, 1);
                        _this.welcomeConfig.attachmentList.splice(newIndex, 0, items[0]);

                    },
                    onEnd: function () {
                    },
                });
            },
            //组件中按钮排序
            resortBtn(item, index) {
                let _this = this;
                console.debug("resortItem", item);
                let el = document.getElementById("button-list" + index);
                var Sortables = new Sortable(el, {
                    animation: 200,
                    easing: "cubic-bezier(1, 0, 0, 1)",
                    handle: ".my-handle-btn",
                    onUpdate: function (event) {
                        let newIndex = event.newIndex,
                            oldIndex = event.oldIndex,
                            $li = el.children[newIndex],
                            $oldLi = el.children[oldIndex];
                        // 先删除移动的节点
                        el.removeChild($li);
                        // 再插入移动的节点到原有节点，还原了移动的操作
                        if (newIndex > oldIndex) {
                            el.insertBefore($li, $oldLi);
                        } else {
                            el.insertBefore($li, $oldLi.nextSibling);
                        }
                        let items = item.btnList.splice(oldIndex, 1);
                        item.btnList.splice(newIndex, 0, items[0]);
                        console.debug("resortBtn", item.btnList);
                    },
                    onEnd: function () {
                    },
                });
            },
            selectFromMaterial(item) {
                this.materialType = item.msgtype;
                this.materialCenter = true;
            },
            saveSelectedMaterial(info) {
                this.material = info;
            },
            //从素材库中选择
            selectMaterial() {
                let infomation = {};
                if (this.material.type === "NEWS") {
                    infomation.link = {
                        title: this.material.content.title,
                        desc: this.material.content.summary,
                        picurl: this.material.content.cover,
                        url: redirectUri[process.env.VUE_APP_CONFIG_ENV].massSend + "/article.html?id=" + this.material.id,
                        author: this.material.content.author,
                        content: this.material.content.content,
                        extInfo: {
                            fileList: [{ url: this.material.content.cover }],
                        },

                    };
                    infomation.materialId = this.material.id;
                    infomation.msgtype = "link";
                } else if (this.material.type === "LINK_NEWS") {
                    infomation.externalLink = {
                        title: this.material.content.title,
                        desc: this.material.content.summary,
                        picurl: this.material.content.cover,
                        url: this.material.content.url,
                        extInfo: {
                            fileList: [{ url: this.material.content.cover }],
                        },

                    };
                    infomation.materialId = this.material.id;
                    infomation.msgtype = "external_link";
                } else if (this.material.type === "IMAGE") {
                    var index = this.material.content.url.lastIndexOf("/");
                    let name = this.material.content.url.substring(index + 1, this.material.content.url.length);
                    var imgtype = name.toLowerCase().split(".");
                    let type = imgtype[1].toLowerCase();
                    let size = this.material.content.size / 1024 / 1024 <= 2;
                    if (type !== "jpg" && type !== "png") {
                        this.$message({
                            message: "只能选择JPG,PNG格式的图片素材",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                    if (!size) {
                        this.$message({
                            message: "选择的图片大小不能超过2M",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                    infomation.image = {
                        url: this.material.content.url,
                        name: this.material.content.name,
                        drawnComponents: this.material.content.drawnComponents,
                        extInfo: {
                            fileList: [{ url: this.material.content.url }],
                        },

                    };
                    infomation.materialId = this.material.id;
                    infomation.msgtype = "image";
                } else if (this.material.type === "VIDEO") {
                    infomation.url = "";
                    var index = this.material.content.url.lastIndexOf("/");
                    let name = this.material.content.url.substring(index + 1, this.material.content.url.length);
                    var imgtype = name.toLowerCase().split(".");
                    let type = imgtype[1].toLowerCase();
                    let size = this.material.content.size / 1024 / 1024 <= 10;
                    if (type !== "mp4") {
                        this.$message({
                            message: "只能选择MP4格式的视频素材",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                    if (!size) {
                        this.$message({
                            message: "选择的视频素材不能超过10M",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                    infomation.video = {
                        url: this.material.content.url,
                        name: this.material.content.name,
                        extInfo: {
                            fileList: [{ url: this.material.content.url }],
                        },
                    };
                    infomation.materialId = this.material.id;
                    infomation.msgtype = "video";
                } else if (this.material.type === "TEXT") {
                    infomation.msgtype = "text";
                    infomation.text = {
                        content: this.material.content.content,
                    };
                } else if (this.material.type === "FILE") {
                    let size = this.material.content.size / 1024 / 1024 <= 20;
                    if (!size) {
                        this.$message({
                            message: "选择的文件素材不能超过20M",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                    infomation.file = {
                        url: this.material.content.url,
                        name: this.material.content.name,
                        extInfo: {
                            fileList: [{ url: this.material.content.url }],
                        },
                    };
                    infomation.msgtype = "file";
                    infomation.materialId = this.material.id;
                }
                if (JSON.stringify(infomation) !== "{}") {
                    this.welcomeConfig.attachmentList.push(infomation);
                }
                this.$nextTick(() => {
                    let type = this.$refs.materialSelect.currentMaterialType;
                    if (type === "TEXT") {
                        if (!this.material.content) {
                            this.$message({
                                message: "请选择素材",
                                duration: 2000,
                                type: "warning",
                            });
                            return;
                        }
                    } else {
                        if (JSON.stringify(infomation) === "{}") {
                            this.$message({
                                message: "请选择素材",
                                duration: 2000,
                                type: "warning",
                            });
                            return;
                        }
                    }
                    this.materialCenter = false;
                });
            },
            changeDateRangeOne(value) {
                if (value === "plus(0)" || value === "plus") {
                    this.dateRange.dateRangeTwo = [{
                        name: "当前日期后",
                        value: "plus",
                    }];
                } else if (value === "minus") {
                    this.dateRange.dateRangeTwo = [{ name: "当前日期", value: "plus(0)" }, {
                        name: "当前日期前",
                        value: "minus",
                    }, {
                        name: "当前日期后",
                        value: "plus",
                    }];
                }
                this.dateRange.dateBeforeTime = "";
                this.dateRange.dateAfterType = "plus";
                this.dateRange.dateAfterTime = "";
                this.getVariableName();
            },
            changeDateRangeTwo(value) {
                this.dateRange.dateAfterTime = "";
                this.getVariableName();
            },
            //限制第二个输入框数字
            dateAfterTimeInput() {
                if ((this.dateRange.dateBeforeType === "minus" && this.dateRange.dateAfterType === "minus")) {
                    if (Number(this.dateRange.dateAfterTime) > Number(this.dateRange.dateBeforeTime)) {
                        let berforeTime = JSON.parse(JSON.stringify(this.dateRange.dateBeforeTime));
                        berforeTime = Number(berforeTime) - 1;
                        this.dateRange.dateAfterTime = berforeTime;
                    }
                } else if ((this.dateRange.dateBeforeType === "plus" && this.dateRange.dateAfterType === "plus")) {
                    if (Number(this.dateRange.dateAfterTime) < Number(this.dateRange.dateBeforeTime)) {
                        let berforeTime = JSON.parse(JSON.stringify(this.dateRange.dateBeforeTime));
                        berforeTime = Number(berforeTime) + 1;
                        this.dateRange.dateAfterTime = berforeTime;
                    }
                }
                this.getVariableName();
            },
            //变量名称的获取
            getVariableName() {
                let name = "";
                this.dateRange.dateRangeOne.forEach(item => {
                    if (item.value === this.dateRange.dateBeforeType) {
                        name = item.name;
                    }
                });
                name = name + this.dateRange.dateBeforeTime;
                if (this.dateRange.dateBeforeTime) {
                    name += "天";
                }
                this.dateRange.dateRangeTwo.forEach(item => {
                    if (item.value === this.dateRange.dateAfterType) {
                        name += "~" + item.name;
                    }
                });
                name = name + this.dateRange.dateAfterTime;
                if (this.dateRange.dateAfterTime) {
                    name += "天";
                }
                this.dateRange.name = name;
            },
            //通用变量自定义日期范围处理
            saveCustomDate() {
                if (!this.dateRange.name) {
                    this.$message({
                        message: "请输入变量名称",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if ((this.dateRange.dateBeforeType !== "plus(0)" && !this.dateRange.dateBeforeTime) ||
                    (this.dateRange.dateAfterType !== "plus(0)" && !this.dateRange.dateAfterTime)) {
                    this.$message({
                        message: "请填写日期",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                let dateOne = "";
                let dateTwo = "";
                if (this.dateRange.dateBeforeType !== "plus(0)") {
                    dateOne = "." + this.dateRange.dateBeforeType + "(" + this.dateRange.dateBeforeTime + ").date()";
                } else {
                    dateOne = ".date()";
                }
                if (this.dateRange.dateAfterType !== "plus(0)") {
                    dateTwo = "." + this.dateRange.dateAfterType + "(" + this.dateRange.dateAfterTime + ").date()";
                } else {
                    dateTwo = ".date()";
                }
                let currentEditor = this.checkCurrentEditor;
                let command = currentEditor.commands.get("insertAskComponent");
                let beforeVal = "|${#temporals.format(date.today()";
                let afterVal = "~${#temporals.format(date.today()";
                beforeVal = beforeVal + dateOne + ",'yyyy-MM-dd')}";
                afterVal = afterVal + dateTwo + ",'yyyy-MM-dd')}|";
                let value = beforeVal + afterVal;
                command.execute({
                    tag: "span",
                    options: {
                        name: this.dateRange.name,
                        data: value,
                    },
                });
                this.datePopup = false;
            },
            receptionTimeChange(value) {
                let timeName = "";
                this.receptionNumber.receptionTimeList.forEach(item => {
                    if (item.value === value) {
                        timeName = item.name;
                    }
                });
                this.statisticsRange.timeName = timeName;
            },
            //receptionDateType
            receptionDateType(value) {
                if (value === "plus(0)") {
                    this.receptionNumber.dateRangeTwo = [{
                        name: "当前日期前",
                        value: "minus",
                    }];
                } else if (value === "minus") {
                    this.receptionNumber.dateRangeTwo = [{ name: "当前日期", value: "plus(0)" }, {
                        name: "当前日期前",
                        value: "minus",
                    }];
                }
                this.receptionNumber.dateAfterTime = "";
                this.receptionNumber.dateBeforeTime = "";
                this.receptionNumber.dateAfterType = "minus";
            },
            //
            recepyionDateAfterTimeInput() {
                if ((this.receptionNumber.dateBeforeType === "minus" && this.receptionNumber.dateAfterType === "minus")) {
                    if (Number(this.receptionNumber.dateAfterTime) > Number(this.receptionNumber.dateBeforeTime)) {
                        let berforeTime = JSON.parse(JSON.stringify(this.receptionNumber.dateBeforeTime));
                        berforeTime = Number(berforeTime) - 1;
                        this.receptionNumber.dateAfterTime = berforeTime;
                    }
                }
            },
            receptionChange(value) {
                this.receptionNumber.cOptions.forEach(item => {
                    item.options.forEach(items => {
                        let key = items.type + "," + items.scope;
                        if (key === value) {
                            this.statisticsRange.name = items.name;
                        }
                    });

                });

            },
            //机器人数据变量处理
            saveReception() {
                if (!this.receptionNumber.name) {
                    this.$message({
                        message: "请填写变量名称",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.receptionNumber.receptionTime === "custom") {
                    if ((this.receptionNumber.dateBeforeType === "minus" &&
                        !this.receptionNumber.dateBeforeTime) ||
                        (this.receptionNumber.dateAfterType === "minus" &&
                            !this.receptionNumber.dateAfterTime)) {
                        this.$message({
                            message: "请填写时间",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                } else if (!this.receptionNumber.receptionTime) {
                    this.$message({
                        message: "请选择时间",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                let currentEditor = this.checkCurrentEditor;
                let command = currentEditor.commands.get("insertAskComponent");
                let variableDetail = null;
                let receptionNumber = "";
                let beforeVal = "${bot.statistics(scopeType,scope,date.today()";
                let afterVal = "date.today()";
                let str = this.receptionNumber.receptionRange;
                str = str.replace(",", "','");
                if (this.receptionNumber.receptionTime === "custom") {
                    if (this.receptionNumber.dateBeforeType !== "plus(0)") {
                        beforeVal = beforeVal + "." + this.receptionNumber.dateBeforeType + "(" + this.receptionNumber.dateBeforeTime + ")";
                    }
                    if (this.receptionNumber.dateAfterType !== "plus(0)") {
                        afterVal = afterVal + "." + this.receptionNumber.dateAfterType + "(" + this.receptionNumber.dateAfterTime + ")";
                    }
                    variableDetail = beforeVal + "," + afterVal + ")." + this.variableDetail.value + "()}";
                    variableDetail = variableDetail.replace(/scopeType,scope/, "'" + str + "'");
                } else {
                    receptionNumber = JSON.parse(JSON.stringify(this.receptionNumber.receptionTime));
                    variableDetail = receptionNumber.replace(/scopeType,scope/, "'" + str + "'");
                    variableDetail = variableDetail.replace(/reception_num/, this.variableDetail.value);
                }
                console.debug("variableDetail", variableDetail);
                command.execute({
                    tag: "span",
                    options: {
                        name: this.statisticsRange.name + this.statisticsRange.timeName + this.receptionNumber.name,
                        data: variableDetail,
                    },
                });
                this.receptionPopup = false;
            },
            //保存所选城市
            saveCity() {
                if (!this.detailCity) {
                    this.$message({
                        message: "请选择城市",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                let currentEditor = this.checkCurrentEditor;
                let command = currentEditor.commands.get("insertAskComponent");
                this.variableDetail.value = this.variableDetail.value.replace(/cityCode/, this.detailCity.code);
                command.execute({
                    tag: "span",
                    options: {
                        name: this.detailCity.name + this.variableDetail.name,
                        data: this.variableDetail.value,
                    },
                });
                this.selectCity = false;
            },
            // 获取机器人数据变量中的统计范围
            getStatisticsScopes() {
                this.receptionNumber.cOptions = [
                    {
                        label: "网站接入",
                        options: [],
                    },
                    {
                        label: "企业微信",
                        options: [],
                    },
                    {
                        label: "微信小程序",
                        options: [],
                    },
                    {
                        label: "微信公众号",
                        options: [],
                    },
                    {
                        label: "钉钉",
                        options: [],
                    },
                    {
                        label: "微信客服",
                        options: [],
                    },
                    {
                        label: "机器人",
                        options: [],
                    },
                ];
                this.FetchGet(this.requestUrl.statistics.getStatisticsScopes).then(
                    (res) => {
                        if (res.code === "0") {
                            this.scopeList = res.data;
                            this.scopeList.forEach((item) => {
                                if (item.type == "APPLET") {
                                    this.receptionNumber.cOptions[2].options.push(item);
                                } else if (item.type == "BOT") {
                                    this.receptionNumber.cOptions[6].options.push(item);
                                } else {
                                    switch (item.appType) {
                                        case 0:
                                            this.receptionNumber.cOptions[1].options.push(item);
                                            break;
                                        case 1:
                                            this.receptionNumber.cOptions[3].options.push(item);
                                            break;
                                        case 2:
                                            // this.cOptions[0].options.push(item);
                                            break;
                                        case 3:
                                            this.receptionNumber.cOptions[4].options.push(item);
                                            break;
                                        case 4:
                                            this.receptionNumber.cOptions[0].options.push(item);
                                            break;
                                        case 5:
                                            this.receptionNumber.cOptions[5].options.push(item);
                                            break;

                                        default:
                                            break;
                                    }
                                }
                            });

                            this.receptionNumber.receptionRange =
                                this.scopeList[0].type +
                                "," +
                                this.scopeList[0].scope;
                            this.statisticsRange.name = this.scopeList[0].name;
                            this.statisticsRange.timeName = "";
                        } else {
                            this.$message.error(res.message);
                        }
                    },
                );
            },
            //获取意图列表
            getIntent(value) {
                let url = "/scrm-api/intermediate/ask-bot-skill-tree?apiKey=" + value;
                this.FetchGet(url).then(res => {
                  this.$set(this.intentList,value,[]);
                  this.$set(this.intentList,value,res);
                });
                this.isKey += 1;
            },
            //组件选择
            componentCheck(item) {
                let info = {};
                if (item.type === "link") {
                    info = {
                        msgtype: item.type,
                        link: {
                            desc: "",
                            picurl: "",
                            title: "",
                            url: "",
                            content: "",
                            author: "",
                            extInfo: {
                                fileList: [],
                            },
                        },
                        materialId: "",
                    };
                } else if (item.type === "image" || item.type === "video" || item.type === "file") {
                    info[item.type] = {
                        name: "",
                        url: "",
                        extInfo: {
                            fileList: [],
                        },
                    };
                    info.materialId = "";
                    info.msgtype = item.type;
                } else if (item.type === "botcard") {
                    info = {
                        msgtype: item.type,
                        botcard: {
                            apiKey: "",
                            desc: "",
                            initIntentId: "",
                            picurl: "",
                            title: "",
                            extInfo: {
                                fileList: [],
                            },
                        },
                    };
                } else if (item.type === "text") {
                    info = {
                        text: {
                            content: "",
                        },
                        materialId: "",
                        msgtype: item.type,
                    };
                } else {
                    info[item.type] = {
                        content: "",
                        btnList: [{ name: "按钮", type: "link", link: { url: "" } }],
                    };
                    info.msgtype = item.type;
                }
                if (item.type === "material") {
                    this.selectFromMaterial(item);
                    return;
                }
                this.welcomeConfig.attachmentList.push(info);
            },
            deleteCardItem(item, index) {
                this.welcomeConfig.attachmentList.splice(index, 1);
            },
            beforeUpload(file, index) {
                this.uploadIndex = index;
            },
            aliyunOssRequestImage(data) {
                let file = data.file;
                console.debug("file", file);
                const fileName = file.name;
                let size = true;
                let message = "";
                if (this.channelType === "DING_TALK") {
                    size = file.size / 1024 / 1024 <= 10;
                    message = "上传的图片大小不能超过10M";
                } else {
                    size = file.size / 1024 / 1024 <= 2;
                    message = "上传的图片大小不能超过2M";
                }
                const fileType = fileName.substring(fileName.lastIndexOf("."));
                if (fileType !== ".jpg" && fileType !== ".png" && fileType !== ".JPG" && fileType !== ".PNG") {
                    this.$message({
                        message: "请选择jpg,png格式的图片",
                        duration: 2000,
                        type: "warning",
                    });
                    let ref = "elUpload" + this.uploadIndex;
                    this.$refs[ref][0].clearFiles();
                    return;
                }
                if (!size) {
                    this.$message({
                        message: message,
                        type: "warning",
                        duration: 2000,
                    });
                    let ref = "elUpload" + this.uploadIndex;
                    this.$refs[ref][0].clearFiles();
                    return;
                }
                let res = upload(ossConfig, file);
                return res;
            },
            //上传图片的校验
            aliyunOssRequest(data) {
                let file = data.file;
                const fileName = file.name;
                let size = file.size / 1024 / 1024 <= 2;
                const fileType = fileName.substring(fileName.lastIndexOf("."));
                if (fileType !== ".jpg" && fileType !== ".png" && fileType !== ".JPG" && fileType !== ".PNG") {
                    this.$message({
                        message: "请选择jpg,png格式的图片",
                        duration: 2000,
                        type: "warning",
                    });
                    let ref = "elUpload" + this.uploadIndex;
                    this.$refs[ref][0].clearFiles();
                    return;
                }
                if (!size) {
                    this.$message({
                        message: "上传的图片大小不能超过2M",
                        type: "warning",
                        duration: 2000,
                    });
                    let ref = "elUpload" + this.uploadIndex;
                    this.$refs[ref][0].clearFiles();
                    return;
                }
                let res = upload(ossConfig, file);
                return res;
            },
            //上传视频的校验
            aliyunOssRequestVideo(data) {
                let file = data.file;
                const fileName = file.name;
                let size = file.size / 1024 / 1024 <= 10;
                const fileType = fileName.substring(fileName.lastIndexOf("."));
                if (fileType !== ".mp4" && fileType !== ".MP4") {
                    this.$message({
                        message: "请选择MP4格式的视频",
                        duration: 2000,
                        type: "warning",
                    });
                    let ref = "elUpload" + this.uploadIndex;
                    this.$refs[ref][0].clearFiles();
                    return;
                }
                if (!size) {
                    this.$message({
                        message: "上传的视频素材不能超过10M",
                        type: "warning",
                        duration: 2000,
                    });
                    let ref = "elUpload" + this.uploadIndex;
                    this.$refs[ref][0].clearFiles();
                    return;
                }
                let res = upload(ossConfig, file);

                return res;

            },
            aliyunOssRequestFile(data) {
                let file = data.file;
                let size = true;
                let message = "";
                if (this.channelType === "DING_TALK") {
                    size = file.size / 1024 / 1024 <= 10;
                    message = "上传的文件素材不能超过10M";
                } else {
                    size = file.size / 1024 / 1024 <= 20;
                    message = "上传的文件素材不能超过20M";
                }

                if (!size) {
                    this.$message({
                        message: message,
                        type: "warning",
                        duration: 2000,
                    });
                    let ref = "elUpload" + this.uploadIndex;
                    this.$refs[ref][0].clearFiles();
                    return;
                }
                this.loadingUpload = true;
                let res = upload(ossConfig, file);
                console.debug("oss upload res", data, res);
                this.showUplodaBtn = false;
                return res;
            },
            onPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //图片，视频，文件上传成功后的赋值
            onSuccess(res, file, fileList, item) {
                if (item.msgtype === "link" || item.msgtype === "botcard") {
                    item[item.msgtype].picurl = res.url;
                } else {
                    item[item.msgtype].url = res.url;
                }
                if (item.msgtype === "image" || item.msgtype === "video" || item.msgtype === "file") {
                    item[item.msgtype].name = file.name;
                }
                item[item.msgtype].extInfo.fileList = [{ url: res.url }];
                console.debug("onSuccess", item, res);
            },
            onRemove(item, index) {
                if (item.msgtype === "link" || item.msgtype === "botcard") {
                    item[item.msgtype].picurl = "";
                } else {
                    item[item.msgtype].url = "";
                }
                if (item.msgtype === "image" || item.msgtype === "video" || item.msgtype === "file") {
                    item[item.msgtype].name = "";
                }
                item[item.msgtype].extInfo.fileList = [];
                let ref = "elUpload" + index;
                if (this.$refs[ref]) {
                    let upload = this.$refs[ref];
                    upload[0].clearFiles();
                }
                console.debug("item", item);
            },
            //点击插入变量，判断应该插入哪个富文本中
            insertVariables(current, item) {
                this.checkCurrentEditor = current;
                this.showVariable = true;
                this.variableType = item.msgtype;
                this.variableList = handleVariables(item,this.channelType);
            },
            closeVariable() {
                this.showVariable = false;
            },
            //选择变量插入
            selectVariable(item, type) {
                let currentEditor = this.checkCurrentEditor;
                this.variableDetail = item;
                let command = currentEditor.commands.get("insertAskComponent");
                if (item.name === "自定义日期范围") {
                    this.datePopup = true;
                    this.dateRange.dateBeforeType = "plus(0)";
                    this.dateRange.dateBeforeTime = "";
                    this.dateRange.dateAfterType = "plus";
                    this.dateRange.dateAfterTime = "7";
                    return;
                }
                if (this.variableType === "datacard" && type === "datecard") {
                    this.receptionPopup = true;
                    this.receptionNumber.name = JSON.parse(JSON.stringify(item.name));
                    this.receptionNumber.title = JSON.parse(JSON.stringify(item.name));
                    this.receptionNumber.receptionTime = "";
                    this.receptionNumber.receptionRange =
                        this.scopeList[0].type +
                        "," +
                        this.scopeList[0].scope;
                    this.statisticsRange.name = this.scopeList[0].name;
                    this.statisticsRange.timeName = "";
                    this.receptionNumber.dateBeforeType = "minus";
                    this.receptionNumber.dateBeforeTime = "";
                    this.receptionNumber.dateAfterType = "plus(0)";
                    this.receptionNumber.dateAfterTime = "";
                    this.receptionNumber.dateRangeOne = [{ name: "当前日期", value: "plus(0)" }, {
                        name: "当前日期前",
                        value: "minus",
                    }];
                    this.receptionNumber.dateRangeTwo = [{ name: "当前日期", value: "plus(0)" }]
                    return;
                }
                if (this.variableType === "weathercard" && type !== "currency") {
                    this.selectCity = true;
                    this.detailCity = "";
                    this.traverseCity(this.cityList);
                    return;
                }
                command.execute({
                    tag: "span",
                    options: {
                        name: item.name,
                        data: item.value,
                    },
                });
            },
            //卡片中增加按钮
            addMessageBtn(item) {
                let info = { name: "按钮", type: "link", link: { url: "" } };
                if (this.channelType === "DING_TALK" || this.channelType === 'DING_TALK_GROUP') {
                    if (item.btnList.length < 3) {
                        item.btnList.push(info);
                    } else {
                        this.$message({
                            message: "钉钉应用卡片按钮不可超过三个",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                } else if (this.channelType === "WE_WORK") {
                    if (item.btnList.length < 6) {
                        item.btnList.push(info);
                    } else {
                        this.$message({
                            message: "企微应用卡片按钮不可超过六个",
                            type: "warning",
                            duration: 2000,
                        });
                        return;
                    }
                } else if (this.channelType === 'YUN_ZHI_JIA'){
                  this.$message({
                    message: "云之家应用卡片按钮不可超过一个",
                    type: "warning",
                    duration: 2000,
                  });
                  return;
                }

            },
            //删除卡片中的按钮
            deleteBtn(item, index) {
                item.btnList.splice(index, 1);
            },
            //天气变量中城市的获取
            getCity() {
                /* let url = "/api/city";*/
                let url = "https://static.guoranbot.com/cdn-common/city/city.json";
                this.FetchGet(url).then(res => {
                    console.debug("getcity", res);
                    if (res.code === "0" && res.data) {
                        this.cityList = res.data;
                    }
                });
            },
            elDropdownvisibleChange(val) {
                this.workOrderSelectIng = val;
            },
            treeNodeClick(data, node) {
                this.traverseCity(this.cityList);
                this.$set(data, "checked", !data.checked);
                this.detailCity = data;
            },
            traverseCity(item) {
                item.forEach(city => {
                    this.$set(city, "checked", false);
                    if (city.children) {
                        this.traverseCity(city.children);
                    }
                });

            },
        },
        mounted() {
            this.getStatisticsScopes();
            this.getCity();
            // this.$eventBus.$on("test-window-visible", (visible) => {
            //     this.isShowTest = visible;
            // });
            // this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
            this.$eventBus.$on("test-window-visible", (visible) => {
                this.isShowTest = visible;
                if(this.isShowTest){
                    this.isShowHeathly = false;
                }
            });
            this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
            this.isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
            this.$eventBus.$on("bot-heathly-visible", (visible) => {
                this.isShowHeathly = visible.flag;
                if(this.isShowHeathly){
                    this.isShowTest = false;
                }
            });
        },
    };
</script>

<style scoped lang="less">
    #sendContent {
        display: flex;
        /* align-items: center;*/
        justify-content: space-between;
        padding-right: 30px;
        position: relative;

        .send-content-left {
            flex: none;
            width: 280px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(29, 55, 129, 0.17);
            height: calc(100vh - 100px);
            overflow: hidden;
        }

        .send-content-right {
            flex: none;
            width: 320px;
            display: flex;
            align-items: center;

            .bg-image {
                /*position: relative;*/
                background: url("../../../assets/images/shilitu.png") no-repeat;
                background-size: 320px 650px;
                transform: scale(0.95, 0.95);
                height: calc(100vh - 100px);
                width: 320px;
            }

            .send-content-preview {
                position: absolute;
                top: 100px;
                left: 30px;
                z-index: 999;
            }
        }

        .send-content-center {
            position: relative;
            width: 40%;
            min-width: 420px;
            margin: 0 30px 0 40px;

            .send-content-center-container {
                flex: none;
                width: 100%;
                height: calc(100vh - 100px);
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 10px;

                .send-content-card-item {
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin-bottom: 30px;
                    text-align: left;

                    .red {
                        color: red;
                    }

                    .send-content-card-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 14px 20px;
                        border-bottom: 1px solid #E0E6F7;

                        .header-right {
                            color: #366AFF;

                            .cursorPointer {
                                cursor: pointer;
                            }

                            .set-padding {
                                padding-right: 20px;
                            }

                            .set-padding-right {
                                padding-right: 12px;
                            }

                            .guoran-a-16-10 {
                                cursor: move;
                            }

                            .guoran-a-16-09 {
                                color: red;
                                cursor: pointer;
                            }
                        }
                    }

                    .card-content {
                        padding: 20px;

                        .upload-class {
                            display: flex;
                            align-items: center;

                            .upload-tip {
                                color: #A9B3C6;
                                font-size: 13px;
                                padding-left: 18px;
                            }

                            .hide-upload /deep/ .el-upload {
                                display: none; /* 上传按钮隐藏 */
                            }

                            /deep/ .el-upload-list__item.is-ready {
                                display: none;
                            }

                            .delete-video-icon {
                                i {
                                    font-size: 18px;
                                    color: red;
                                    padding-left: 10px;
                                }
                            }

                            .picture-item-image {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background-color: #f0f4fc;
                                height: 150px;
                                width: 150px;
                                border-radius: 5px;
                                position: relative;

                                .el-icon-delete {
                                    display: none;
                                }
                            }

                            .picture-item-image:hover {
                                .el-icon-delete {
                                    position: absolute;
                                    display: inline-block;
                                    bottom: 10px;
                                    right: 20px;
                                }
                            }
                        }

                        .message-card {
                            .button-list {
                                padding: 10px 0;

                                .button-item {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    color: #366AFF;
                                    padding: 10px 0;

                                    .button-left {
                                        display: flex;
                                        align-items: center;
                                        flex: 1;

                                        .guoran-a-16-10 {
                                            padding-right: 10px;
                                            cursor: move;
                                        }

                                        .button-name {
                                            flex: 1;
                                        }

                                        .button-link {
                                            flex: 1;
                                        }
                                    }

                                    .button-right {
                                        flex: none;
                                        width: 30px;
                                        text-align: right;
                                    }

                                    .label {
                                        color: #333333;
                                        padding-bottom: 6px;
                                    }
                                }
                            }

                            .add-button {
                                padding-top: 14px;
                                border-top: 1px solid #E0E6F7;
                                display: flex;
                                align-items: center;
                                color: #366AFF;
                                cursor: pointer;

                                .add-icon {
                                    width: 30px;
                                    height: 30px;
                                    background: #366AFF;
                                    border-radius: 5px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                    margin-right: 10px;
                                    flex: none;
                                }
                            }
                        }

                        .link-class {
                            .link-label {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .label-right {
                                    color: #366AFF;
                                    cursor: pointer;
                                }
                            }

                            .label-content {
                                /deep/ .ck-editor__editable {
                                    height: 60px !important;
                                }
                            }

                            .text-content {
                                /deep/ .ck-editor__editable {
                                    height: 300px !important;
                                }
                            }
                        }

                        .bot-card {
                            .link-label {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .label-right {
                                    color: #366AFF;
                                    cursor: pointer;
                                }
                            }

                            .label-content {
                                /deep/ .ck-editor__editable {
                                    height: 60px !important;
                                }
                            }
                        }

                        /deep/ .ck-editor__editable {
                            height: 150px !important;
                        }

                        /deep/ .el-form-item__label {
                            line-height: 20px;
                            width: 100%;
                        }

                        /deep/ .el-form-item__content {
                            line-height: 20px;
                        }

                        /deep/ .el-upload--picture-card {
                            width: 96px;
                            height: 96px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        /deep/ .ask-component-placeholder {
                            background-color: rgba(0, 89, 255, .2);
                            padding: 4px 5px;
                            border-radius: 3px;
                            margin-right: 10px;
                            margin-bottom: 8px;
                            line-height: 30px;
                        }

                        /deep/ .el-upload-list__item-thumbnail {
                            object-fit: cover;
                        }
                    }


                }
            }

            .variableDialog {
                position: absolute;
                top: 0;
                right: -330px;
                z-index: 999;
                background-color: white;
                width: 320px;
                height: 600px;
                box-shadow: 0px 0px 8px 0px rgba(29, 55, 129, 0.17);
                border-radius: 5px;
            }
        }

        /deep/ .selectMaterial {
            #popup-assembly {
                width: 860px !important;
            }
          .defaultDialog{
            margin-top: 2vh!important;
          }
        }

        /deep/ .popup-floating-layer {
            text-align: left;

            .popup-header {
                padding-bottom: 20px;

                .popup-tip {
                    padding-top: 0;
                }
            }

            .dialog-footer {
                padding: 20px 0;
            }

            .member-footer {
                .el-button {
                    border-radius: 17px;
                }

                .el-button--primary {
                    background-color: #366AFF;
                    border-color: #366AFF;
                }
            }

        }

        .setDateRange {
            /deep/ #popup-assembly {
                width: 700px !important;
            }

            /deep/ .el-form-item__label {
                line-height: 20px !important;
            }

            .date-range {
                display: flex;
                align-items: center;
            }

            .date-range-select {
                padding-top: 20px;
            }
        }

        .look-image {
            img {
                height: 500px;
                object-fit: contain;
            }
        }
    }

    #el-dropdown-work-order {
        width: 100%;
    }

    .active-el-dropdown-link {
        border: 1px solid #366AFF !important;
    }

    .el-dropdown-link-workorder {
        display: flex;
        border: 1px solid #dcdfe6;
        border-radius: 4px;

        /deep/ .el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content {
            background: none !important;
        }

        .el-dropdown-work-order-content {
            flex: auto;
            display: flex;
            align-items: center;
            height: 38px;
            padding: 0 18px;

            .el-tag {
                margin: 2px 5px 0 0;
            }
        }

        .el-dropdown-placeholder {
            flex: auto;
            display: block;
            height: 38px;
            line-height: 38px;
            padding: 0 18px;
            color: #c0c4cc;
        }

        .el-icon {
            flex: none;
            width: 30px;
            line-height: 38px;
            text-align: center;
            display: flex;
            align-items: center;

            em {
                align-items: center;
            }
        }
    }

    #work-order-dropdown-person {
        overflow-x: auto;

        .el-dropdown-menu__item {
            width: 380px;
            height: auto !important;
            background-color: chocolate;
            padding: 0 !important;
        }
    }

    .work-order-dropdown-item-person {
        width: 480px !important;
        padding: 0 !important;
    }

    .custom-tree-node {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            font-weight: 600;
            font-size: 18px;
        }
    }
</style>