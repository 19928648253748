<template>
    <div class="taskContent">
        <div class="task-header">
            <div class="task-header-left">
                <div class="header-back" @click="goBack">
                    <i class="iconfont guoran-a-18-22"></i>
                </div>
                <div class="header--title-name">
                    <i class="iconfont"></i>
                    <span>{{headerTitle}}</span>
                </div>
            </div>
            <div class="task-header-center">
                <div class="task-header-item"
                     v-for="(item,index) in headerList"
                     :key="index"
                     @click="headerTab(item)"
                     :class="item.isActive?'active-header':''"
                >
                <span class="header-name">
                    <i class="iconfont " :class="item.index===1?'guoran-shuzi-1':'guoran-shuzi-2'"></i>
                    <span>{{item.name}}</span>
                </span>
                </div>
            </div>
            <div class="task-header-right">
                <span class="publish-btn" @click="publishTask">
                    <i class="iconfont guoran-a-16-05"></i>
                    发布
                </span>
            </div>

        </div>
        <div class="task-container">
            <div class="task-information" v-show="activeHeader==='任务信息'">
                <div class="infomation-container">
                    <el-form :style="{pointerEvents: notEditTask?'none':'auto',}" label-position="top">
                        <el-form-item
                                label="任务名称"
                                label-width="120px"
                        >
                            <el-input
                                    v-model="taskName"
                                    autocomplete="off"
                                    placeholder="请输入任务名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                                label="通知渠道"
                                label-width="120px"
                        >
                            <div class="select-channel">
                                <el-radio-group
                                        v-model="channelType"
                                        @change="channelValueChange"
                                >
                                    <el-radio label="WE_WORK">企业微信应用</el-radio>
                                    <el-radio label="DING_TALK">钉钉应用</el-radio>
                                    <el-radio label="DING_TALK_GROUP">钉钉群</el-radio>
                                    <el-radio label="YUN_ZHI_JIA">云之家</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label="发送范围"
                                label-width="120px">
                            <div
                                    class="form-item-box">
                                <el-select
                                        v-model="selectedAgentId"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        clearable
                                        @change="changeSendAgentInfo"
                                        v-if=" channelType ==  'WE_WORK' "
                                >
                                    <el-option
                                            :class="selectedAgentId===item.agentId?'send-range-item':''"
                                            v-for="item in sendingAgentList"
                                            :key="item.agentId"
                                            :label="item.agentName"
                                            :value="item.agentId">
                                    </el-option>
                                </el-select>
                                <el-select
                                        v-model="selectedAgentId"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        clearable
                                        @change="changeSendAgentDing"
                                        v-else-if=" channelType ==  'DING_TALK' "
                                >
                                    <el-option
                                            :class="selectedAgentId===item.bindCode?'send-range-item':''"
                                            v-for="item in dingTalkList"
                                            :key="item.bindCode"
                                            :label="item.agentName"
                                            :value="item.bindCode">
                                    </el-option>
                                </el-select>
                                <el-select
                                        v-model="selectedAgentId"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        clearable
                                        @change="changeSendAgentDing"
                                        v-else-if=" channelType ==  'DING_TALK_GROUP' "
                                >
                                    <el-option
                                            :class="selectedAgentId===item.bindCode?'send-range-item':''"
                                            v-for="item in dingTalkGroup"
                                            :key="item.bindCode"
                                            :label="item.agentName"
                                            :value="item.bindCode">
                                    </el-option>
                                </el-select>
                                <el-select
                                  v-model="selectedAgentId"
                                  placeholder="请选择"
                                  style="width: 100%"
                                  clearable
                                  @change="changeSendAgentDing"
                                  v-else-if=" channelType ==  'YUN_ZHI_JIA' "
                              >
                                <el-option
                                    :class="selectedAgentId===item.bindCode?'send-range-item':''"
                                    v-for="item in yunzhijiaList"
                                    :key="item.bindCode"
                                    :label="item.agentName"
                                    :value="item.bindCode">
                                </el-option>
                              </el-select>
                            </div>

                            <div class="send-form-box"
                                 v-show=" channelType ==  'WE_WORK' || channelType ==  'DING_TALK' || channelType=== 'YUN_ZHI_JIA'">
                                <div
                                        class="form-item-box">
                                    <el-select
                                            v-model="sendingRule"
                                            style="width: 100%"
                                            placeholder="请选择"
                                            class="send-range"
                                            @change="clearUserTag"
                                    >
                                        <el-option
                                                :class="sendingRule===item.value?'send-range-item':''"
                                                v-for="item in sendingRulesList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="newCustomer"
                                     v-show="(channelType ==  'WE_WORK'|| channelType ==  'DING_TALK' || channelType=== 'YUN_ZHI_JIA')&&sendingRule==='NEW_EMPLOYEE'">
                                    <div v-if="selectedAgentInfo.bindType==1" class="alert-text">
                                        <el-alert
                                                type="warning"
                                                :closable="false"
                                                show-icon>
                                            <span slot="title">
                                                <a href="https://appcenter.dingtalk.com/detail.html?goodsCode=DT_GOODS_881644906932786&sig=8399b4baefc64fa36e16a3881b48f53c"
                                                   target="_blank">
                                                    仅在安装AskBot员工AI助手第三方应用后系统能够感应新员工入职
                                                </a>
                                            </span>
                                        </el-alert>
                                    </div>


                                </div>
                                <div class="sending-range">
                                    <div class=" bind-bot-self-tree-box"
                                         v-if="selectedAgentId&&channelType ==  'WE_WORK'"
                                    >
                                        <div @click.stop="setActiveTreeIndex"
                                             :class="['selected-box',]"
                                        >
                                            <div class="tag-box">
                                        <span class=" placeholder"
                                              v-if="employeeConfigTag.length === 0">请点击选择发送范围</span>
                                                <el-tag
                                                        v-for="(tagCell,tagCellIndex) in employeeConfigTag"
                                                        :key=" tagCellIndex"
                                                        size="mini"
                                                        @close="handleCloseOrganization( tagCellIndex)"
                                                        closable
                                                >
                                                    <span v-if="selectedAgentInfo.bindType===0&&selectedAgentInfo.wechatType===0">
                                                        <span v-if="tagCell.isMember || tagCell.type == 0">
                                                          <open-data :type="'userName'" :openid="tagCell.label"></open-data>
                                                        </span>
                                                        <span v-else>
                                                          <open-data :type="'departmentName'" :openid="tagCell.label"></open-data>
                                                        </span>
                                                    </span>
                                                    <span v-else>
                                                        {{tagCell.label}}
                                                    </span>
                                                </el-tag
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class=" bind-bot-self-tree-box"
                                         v-if="selectedAgentId && (channelType ==  'DING_TALK' || channelType=== 'YUN_ZHI_JIA')"
                                    >
                                        <div @click.stop="setActiveDingTreeIndex"
                                             :class="['selected-box',]"
                                        >
                                            <div class="tag-box">
                                        <span class=" placeholder"
                                              v-if="employeeConfigTag.length === 0">请点击选择发送范围</span>
                                                <el-tag
                                                        v-for="(tagCell,tagCellIndex) in employeeConfigTag"
                                                        :key=" tagCellIndex"
                                                        size="mini"
                                                        @close="handleCloseOrganization( tagCellIndex)"
                                                        closable
                                                >{{tagCell.label}}
                                                </el-tag
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="induction-days"
                                         v-show=" sendingRule === 'SPECIAL_DATE_FOR_NEW_EMPLOYEE' &&
                                    (channelType ==  'WE_WORK'|| channelType ==  'DING_TALK')"
                                    >
                                        <span style="padding-right: 10px">入职后</span>
                                        <el-input
                                                v-model="inductionDaysNumber"
                                                placeholder="请输入"
                                                type="number"
                                                min="1"
                                                size="small"
                                                style="width: 100px;margin-right: 12px"
                                                v-if="inductionDaysType!=='EVERY_YEAR'"
                                        ></el-input>
                                        <el-select
                                                v-model="inductionDaysType"
                                                placeholder="请选择"
                                                style="width: 100px"
                                                size="small"
                                                @change="changeInductionDay"
                                        >
                                            <el-option
                                                    :class="inductionDaysType===item.value?'send-range-item':''"
                                                    v-for="item in inductionDaysTypeList"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                        <span style="padding-left: 10px;">的员工</span>
                                    </div>
                                </div>
                            </div>


                        </el-form-item>
                        <el-form-item
                                label="发送时间"
                                label-width="120px"
                                v-show="sendingRule!=='SPECIAL_DATE_FOR_NEW_EMPLOYEE'"
                        >
                            <div class="send-date one-row">
                                <div v-show="sendingRule === 'GENERAL'"
                                     class="sendTime"
                                >
                                    <el-radio-group
                                            v-model="sendTimeType"
                                    >
                                        <el-radio
                                                label="NOW"
                                        >立即发送
                                        </el-radio
                                        >
                                        <el-radio
                                                label="TIMED"
                                        >定时发送
                                        </el-radio
                                        >
                                        <el-radio
                                                label="PERIODIC"
                                        >周期发送
                                        </el-radio
                                        >
                                    </el-radio-group>
                                </div>
                                <div v-show="sendingRule ==='NEW_EMPLOYEE'"
                                     class="new-employee-induction"
                                >
                                    <span>新员工入职后立即发送</span>
                                </div>
                                <div v-show="sendingRule ==='BIRTHDAY'"
                                     class="new-employee-induction"
                                >
                                    <span>过生日当天</span>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label=""
                                v-show="sendingRule === 'SPECIAL_DATE_FOR_NEW_EMPLOYEE' &&
                                (newCustomer === -1 ||
                                 newCustomer === 1)"
                        >
                            <div style=" display: flex;justify-content: flex-start;"
                            >
                                <span
                                        v-show="newCustomer === -1">之前</span>
                                <span
                                        v-show="newCustomer === 1">之后</span>
                                <el-input
                                        placeholder="请输入"
                                        style="
                                                            width: 30%;
                                                            margin: 0 10px;
                                                        "
                                        v-model="specialDay"
                                        type="number"
                                        min="1"
                                ></el-input
                                >
                                天
                            </div>
                        </el-form-item>
                        <el-form-item
                                label-width="120px"
                                label="周期规则"
                                v-show="sendingRule === 'GENERAL' &&
                                 sendTimeType === 'PERIODIC' "
                        >
                            <div class="periodic-rule">
                                <el-radio-group
                                        v-model="periodicRule"
                                >
                                    <el-radio
                                            v-for="( periodic, index) in periodicRuleList"
                                            :label="periodic.value"
                                            :key="index"
                                    >{{
                                        periodic.label
                                        }}
                                    </el-radio
                                    >
                                </el-radio-group>
                            </div>

                        </el-form-item>
                        <el-form-item
                                label-width="120px"
                                label="选择重复"
                                v-show="sendingRule === 'GENERAL' &&
                                sendTimeType === 'PERIODIC' &&
                                (periodicRule === 'WEEK' || periodicRule ==='MOUTH')"
                        >
                            <div class="periodic-rule">
                                <el-checkbox-group
                                        v-model="setWeek"
                                        v-if=" periodicRule === 'WEEK'"
                                >
                                    <el-checkbox
                                            v-for="( week, index) in weekList"
                                            :label="week.value"
                                            :value="week.value"
                                            :key="index"
                                    >{{
                                        week.label
                                        }}
                                    </el-checkbox
                                    >
                                </el-checkbox-group>
                            </div>
                            <div class="periodic-rule">
                                <el-radio-group
                                        v-model="setMonthTime"
                                        v-if=" periodicRule === 'MOUTH'"
                                >
                                    <el-radio :label="1"
                                    >每月第一天
                                    </el-radio
                                    >
                                    <el-radio :label="-1"
                                    >每月最后一天
                                    </el-radio
                                    >
                                </el-radio-group>
                            </div>

                        </el-form-item>
                        <el-form-item
                                label-width="120px"
                                label="选择日期"
                                v-show="sendTimeType ==='PERIODIC'
                                &&periodicRule === 'YEAR' &&
                                sendingRule === 'GENERAL' "
                        >
                            <div class="periodic-rule">
                                <el-radio-group
                                        v-model="GregorianOrLunar"
                                        style="margin-right: 20px"
                                >
                                    <el-radio
                                            v-for="(data, index ) in datePickerList"
                                            :label="data.value"
                                            :key="index"
                                    >
                                        {{ data.label }}
                                    </el-radio>
                                </el-radio-group>
                                <el-date-picker
                                        v-model="dataPicker"
                                        type="date"
                                        placeholder="选择日期"
                                        :picker-options="dateOption"
                                        size="small"
                                >
                                </el-date-picker>
                            </div>

                        </el-form-item>
                        <el-form-item
                                label="选择时间"
                                label-width="120px"
                                v-show="sendingRule ==='BIRTHDAY' ||
                                sendingRule ==='SPECIAL_DATE_FOR_NEW_EMPLOYEE' ||
                                (sendingRule ==='GENERAL' &&
                                sendTimeType !== 'NOW')"
                        >
                            <div
                                    v-if="sendingRule !=='SPECIAL_DATE_FOR_NEW_EMPLOYEE' &&
                                    sendingRule !=='BIRTHDAY'"
                            >
                                <el-time-picker
                                        v-model="checkTime"
                                        placeholder="任意时间点"
                                        v-if="sendTimeType !=='TIMED'"
                                        style="width: 100%"
                                >
                                </el-time-picker>
                                <el-date-picker
                                        v-model="checkDateTime"
                                        type="datetime"
                                        v-if="sendTimeType === 'TIMED'"
                                        :picker-options="dateOption"
                                        @change="dateHandle"
                                        :default-time="dateOptionTime"
                                        placeholder="选择日期时间"
                                        style="width: 100%"
                                >
                                </el-date-picker>
                            </div>
                            <div
                                    v-if="sendingRule ==='SPECIAL_DATE_FOR_NEW_EMPLOYEE' ||
                                   sendingRule ==='BIRTHDAY'"
                            >
                                <el-time-picker
                                        v-model="specialDateTime"
                                        placeholder="任意时间点"
                                        style="width: 100%"
                                >
                                </el-time-picker>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div v-show="activeHeader==='发送内容'" class="task-sending-content">
                <send-content :botList="agentBotInfos" :welcomeConfig="welcomeConfig" ref="sendContent"
                              :channelType="channelType"></send-content>
            </div>
        </div>
        <popup v-if="memberDialogTableVisible"
               @closeEvent="memberDialogTableVisible=false"
               class="select-member"
        >
            <div slot="popup-name" class="popup-name">选择成员</div>
            <div slot="popup-con" class="wechatMemberTree">
<!--                <DepartmentTree :data="memberList"
                                @onNodeClick="onNodeClick"
                                :selectedAgentInfo="selectedAgentInfo"
                                :sendingRule="sendingRule"
                ></DepartmentTree>-->
              <el-input placeholder="请搜索"
                        v-model="keyword"
                        @keyup.enter.native="searchEmployee"
                        @input="searchKeyNull"
                        size="medium"
                        style="margin-bottom: 10px"
              ></el-input>
              <el-tree
                  key="selectOrganizationTreeLazy"
                  :data="memberList"
                  id="selectOrganizationTreeLazy"
                  class="filter-tree"
                  node-key="id"
                  :props="defaultProps"
                  :expand-on-click-node="false"
                  :load="loadNode"
                  lazy>
                  <span
                      class="custom-tree-node"
                      slot-scope="{node,data}" @click.stop="handleNodeClick(node,data)">
                    <span class="custom-tree-node-left" :class="data.dataType === 'hasNext' ?'loadMore':''">
                      <!-- // 员工树结构的数据 type=0是员工，type=1是部门 -->
                      <span
                          class="data-label"
                          v-if="data.dataType !== 'hasNext' && (data.isWeWorkThirdData || (selectedAgentInfo.bindType===0&&selectedAgentInfo.wechatType===0))">
                        <open-data :type="data.type == 1 || data.type === 'DEPARTMENT' ? 'departmentName' : 'userName'" :openid="data.label"></open-data>
                      </span>
                      <span v-else class="data-label">{{data.label }}</span>
                      <template v-if="data.originData && data.originData.departments && keyword">
                            <span>&#8195</span>
                             <div
                                 v-for="(cell, index) in data.originData.departments"
                                 :key="index"
                             >
                                <el-tooltip placement="top" effect="light">
                                    <div slot="content">
                                      (
                                        <span
                                            v-if="
                                                cell.source === 1 &&
                                                data.isWeWorkThirdData
                                            "
                                        >
                                          <open-data :type="'departmentName'"  :openid="cell.fullName"></open-data>
                                        </span>
                                        <span
                                            v-else
                                            v-html="cell.fullName"
                                        ></span>
                                      )
                                    </div>
                                    <span
                                        v-if="
                                            cell.source === 1 &&
                                            data.isWeWorkThirdData
                                        "
                                    >
                                      ( <open-data :type="'departmentName'"  :openid="cell.name"></open-data> )
                                    </span>
                                    <span v-else>({{ cell.name }})</span>
                                </el-tooltip>
                            </div>
                          </template>
                    </span>
                    <span v-show="data.checked">
                      <i :class="['el-icon-check']"></i>
                    </span>
                  </span>
              </el-tree>
            </div>
            <div slot="dialog-footer" class="member-footer">
                <el-button @click="memberDialogTableVisible = false" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="confirmMember" size="small">保存</el-button>
            </div>
        </popup>
        <popup v-if="memberDialogDingTableVisible"
               @closeEvent="memberDialogDingTableVisible=false"
               class="select-member">
            <div slot="popup-name" class="popup-name">选择成员</div>
            <div slot="popup-con" class="ding-talk-member">
              <el-input placeholder="请搜索"
                        v-model="keyword"
                        @keyup.enter.native="searchEmployee"
                        @input="searchKeyNull"
                        size="medium"
                        style="margin-bottom: 10px"
              ></el-input>
                <el-tree :data="memberList"
                         :props="defaultProps"
                         node-key="id"
                         ref="projectTree"
                         :expand-on-click-node="false"
                         :load="loadNode"
                         lazy
                >
                    <span class="custom-tree-node"
                          slot-scope="{ node, data }"
                          @click="handleNodeClick(node,data)"
                    >
                        <span class="custom-tree-node-left" :class="data.dataType === 'hasNext' ?'loadMore':''">
                            <span v-if="data.dataType !== 'hasNext'">
                                <i v-if="data.type===1"
                                   class="arsenal_icon arsenalsitemap1"
                                   style="padding-right: 6px;font-size: 13px;"
                                ></i>
                                <i v-else
                                   class="arsenal_icon arsenaluser1"
                                   style="padding-right: 6px;font-size: 13px;"
                                ></i>
                            </span>
                            <span>{{ data.label }}</span>
                          <template v-if="data.originData && data.originData.departments && keyword">
                            <span>&#8195</span>
                             <div
                                 v-for="(cell, index) in data.originData.departments"
                                 :key="index"
                             >
                                <el-tooltip placement="top" effect="light">
                                    <div slot="content">
                                      (
                                        <span
                                            v-if="
                                                data.isWeWorkThirdData
                                            "
                                        >
                                          <open-data :type="'departmentName'"  :openid="cell.fullName"></open-data>
                                        </span>
                                        <span
                                            v-else
                                            v-html="cell.fullName"
                                        ></span>
                                      )
                                    </div>
                                    <span
                                        v-if="
                                            data.isWeWorkThirdData
                                        "
                                    >
                                      ( <open-data :type="'departmentName'"  :openid="cell.name"></open-data> )
                                    </span>
                                    <span v-else>({{ cell.name }})</span>
                                </el-tooltip>
                            </div>
                          </template>
                        </span>
                        <span v-if="data.checked"><i class="el-icon-check"></i></span>
                    </span>
                </el-tree>
            </div>
            <div slot="dialog-footer" class="member-footer">
                <el-button @click="memberDialogDingTableVisible = false" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="confirmMember" size="small">保存</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
    import SendContent from "./sendContent";
    import Popup from "./popup";
    import DepartmentTree from "./DepartmentTree";
    import OpenData from "@/components/openData";
    import axios from "axios";
    export default {
        name: "taskContent",
        components: { OpenData, DepartmentTree, Popup, SendContent },
        data() {
            return {
                headerList: [
                    { name: "任务信息", index: 1, isActive: true },
                    { name: "发送内容", index: 2, isActive: false },
                ],
                activeHeader: "任务信息",
                taskName: "",
                channelType: "WE_WORK",
                checkBeforeChannelType: "WE_WORK",
                elSelectPlaceholder: "请选择应用类型",
                isSelectedChannelDisable: true,
                channelList: [
                    {
                        value: "0",
                        label: "企微-果然SCRM",
                    },
                    {
                        value: "1",
                        label: "公众号-客户管理",
                    },
                ],
                selectedChannel: "",
                selectedGroupType: "EMPLOYEE",
                selectedAgentId: "",
                selectedSecret: "",
                selectedAgentInfo: {},
                sendingAgentList: [],//发送消息的应用列表
                agentId2AgentInfo: {},
                isGroupTypeDisable: true,
                sendingRulesList: [
                    {
                        value: "GENERAL",
                        label: "通用消息",
                    },
                    {
                        value: "NEW_EMPLOYEE",
                        label: "新员工入职",
                    },
                    {
                        value: "BIRTHDAY",
                        label: "员工生日祝福",
                    },
                    {
                        value: "SPECIAL_DATE_FOR_NEW_EMPLOYEE",
                        label: "入职特殊日期",
                    },
                ],
                sendingFilterRules: [
                    {
                        value: "GENERAL",
                        label: "通用消息",
                    },
                    {
                        value: "BIRTHDAY",
                        label: "员工生日祝福",
                    },
                    {
                        value: "SPECIAL_DATE_FOR_NEW_EMPLOYEE",
                        label: "入职特殊日期",
                    },
                ],
                sendingRule: "GENERAL",
                customerScopeList: [
                    {
                        value: "EMPLOYEE",
                        label: "按员工选择",
                    },
                    {
                        value: "TAG",
                        label: "按标签选择",
                    },
                    {
                        value: "ALL",
                        label: "全部客户",
                    },
                ],
                customerScopeName: "ALL",
                inductionDaysNumber: 1,
                inductionDaysTypeList: [
                    {
                        value: "DAY",
                        label: "天",
                    },
                    {
                        value: "WEEK",
                        label: "周",
                    },
                    {
                        value: "MONTH",
                        label: "月",
                    },
                    {
                        value: "YEAR",
                        label: "年",
                    },
                    {
                        value: "EVERY_YEAR",
                        label: "周年",
                    },
                ],
                inductionDaysType: "DAY",
                form: {
                    props: { multiple: true },
                    options: [],
                },
                employeeConfigUserIds: [],
                employeeConfigTag: [],
                notEditTask: false,
                showTagsList: [],
                sendTimeType: "NOW",
                checkTime: "",
                specialDateTime: "",
                checkDateTime: "",
                sendDays: "1",
                newCustomer: 0,
                dateOption: {
                    disabledDate: (time) => {
                        return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
                    },
                },
                dateOptionTime: new Date(Date.now()).Format("hh:mm:ss"),
                GregorianOrLunar: "CALENDAR",
                datePickerList: [
                    {
                        value: "CALENDAR",
                        label: "公历",
                    },
                    {
                        value: "LUNAR",
                        label: "农历",
                    },
                ],
                periodicRuleList: [
                    {
                        value: "DAY",
                        label: "每天",
                    },
                    {
                        value: "WEEK",
                        label: "每周",
                    },
                    {
                        value: "MOUTH",
                        label: "每月",
                    },
                    {
                        value: "YEAR",
                        label: "每年",
                    },
                ], //周期规则
                periodicRule: "DAY",
                weekList: [
                    {
                        value: 2,
                        label: "周一",
                    },
                    {
                        value: 3,
                        label: "周二",
                    },
                    {
                        value: 4,
                        label: "周三",
                    },
                    {
                        value: 5,
                        label: "周四",
                    },
                    {
                        value: 6,
                        label: "周五",
                    },
                    {
                        value: 7,
                        label: "周六",
                    },
                    {
                        value: 1,
                        label: "周日",
                    },
                ],
                setWeek: [2],
                setMonthTime: 1,
                welcomeConfig: {
                    attachmentList: [],
                    text: {
                        content: "",
                    },
                },
                dataPicker: "",
                specialDay: 1,
                agentBotInfos: [],
                memberDialogTableVisible: false,
                memberList: [],
                userTags: [],
                taskCropId: "",
                selectTask: {},
                headerTitle: "创建任务",
                dingTalkGroup: [],
                dingTalkList: [],
                agentIdDingAgentInfo: {},
                agentIdDingGroupAgentInfo: {},
                memberDialogDingTableVisible: false,
                props: {
                    label: "label",
                    children: "children",
                    isLeaf: "leaf",
                },
                departmentMember: {},
                defaultExpandAll: true,
                originSelectIds: [],
                defaultProps:{
                  label: "label",
                  children: "children",
                  isLeaf: 'leaf'
                },
              keyword:"",
              currentPage:1,
              pageSize:20,
              staffHasNext:false,
              weWorkResolve:"",
              weWorkNode:"",
              dingTalkResolve:"",
              dingTalkNode:"",
              dingMemberNext:{},
              yunzhijiaList:[],
              agentIdYunzhijiaAgentInfo:{}
            };
        },
        methods: {
            getTaskDetail(id) {
                console.debug("id", id);
                let url = "/scrm-api/group-send-message-task/"+id;
                this.FetchGet(url).then(res => {
                    console.debug("taskDetail", res);
                    this.taskName = res.name;
                    this.channelType = res.channel;
                    this.checkBeforeChannelType = JSON.parse(JSON.stringify(res.channel));
                    this.selectedGroupType = res.crowd;
                    if (res.attachmentList) {
                        this.welcomeConfig.attachmentList = JSON.parse(JSON.stringify(res.attachmentList));
                    }
                    this.$refs.sendContent.initIntent();
                    this.welcomeConfig.text = JSON.parse(JSON.stringify(res.text));
                    this.testUserIds = [];
                    this.userTags = [];
                    this.employeeConfigTag = [];
                    this.sendTimeType = "NOW";
                    this.periodicRule = "DAY";
                    this.checkTime = "";
                    this.specialDateTime = "";
                    this.newCustomer = 0;
                    this.inductionDaysNumber = 1;
                    this.checkDateTime = "";
                    this.specialDay = 1;
                    this.inductionDaysType = "DAY";
                    this.setWeek = [2];
                    this.showTagsList = [];
                    this.groupIds = [];
                    this.selectTask = res;
                    this.notEditTask = false;
                    this.selectedAgentId = "";
                    this.selectedSecret = "";
                    this.taskCropId = "";
                    this.originSelectIds = [];
                    this.initDefault(res.crowd, this.selectTask);
                });
            },
            //切换发送类型初始化数据
            initDefault(type, value) {
                if (value.weWorkEmployeeConfig) {
                    this.taskCropId = value.weWorkEmployeeConfig.cropId;
                    if (value.weWorkEmployeeConfig.sendTimeType) {
                        this.sendTimeType =
                            value.weWorkEmployeeConfig.sendTimeType;
                    }
                    if (value.weWorkEmployeeConfig.weWorkAgentId) {
                        this.selectedAgentId = value.weWorkEmployeeConfig.weWorkAgentId;
                        if (value.weWorkEmployeeConfig.originSelectIds) {
                            setTimeout(() => {
                                this.$nextTick(() => {
                                    this.selectedAgentInfo = this.agentId2AgentInfo[this.selectedAgentId];
                                    console.debug('selectedAgentInfo',this.selectedAgentInfo)
                                  if (this.selectedAgentInfo){
                                    this.loadMemberInfo(this.agentId2AgentInfo[this.selectedAgentId].corpId, this.agentId2AgentInfo[this.selectedAgentId].agentId, this.agentId2AgentInfo[this.selectedAgentId].secret, value.weWorkEmployeeConfig.employeeSelectedConfigs, this.agentId2AgentInfo[this.selectedAgentId].thirdId, this.agentId2AgentInfo[this.selectedAgentId].bindType);
                                    this.loadBotInfo(this.agentId2AgentInfo[this.selectedAgentId].agentId, this.agentId2AgentInfo[this.selectedAgentId].corpId);
                                  }
                                });
                            }, 500);

                        }
                    }
                    if (value.weWorkEmployeeConfig.weWorkAgentSecret) {
                        this.selectedSecret = value.weWorkEmployeeConfig.weWorkAgentSecret;
                    }
                    this.sendingRule = value.sendType;
                    if (this.sendTimeType === "TIMED") {
                        if (value.weWorkEmployeeConfig.timed.date) {
                            this.checkDateTime =
                                value.weWorkEmployeeConfig.timed.date;
                        }
                    }
                    if (value.sendType === "BIRTHDAY") {
                        if (
                            value.weWorkEmployeeConfig.birthDayDateConfig
                        ) {
                            this.specialDateTime =
                                value.weWorkEmployeeConfig.birthDayDateConfig.time;
                        }
                    }
                    if (value.sendType === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
                        if (
                            value.weWorkEmployeeConfig
                                .newEmployeeSpecialDateConfig
                        ) {
                            if (
                                value.weWorkEmployeeConfig
                                    .newEmployeeSpecialDateConfig.diff
                            ) {
                                if (
                                    value.weWorkEmployeeConfig
                                        .newEmployeeSpecialDateConfig.diff < 0
                                ) {
                                    this.newCustomer = -1;
                                    this.specialDay =
                                        -value.weWorkEmployeeConfig
                                            .newEmployeeSpecialDateConfig.diff;
                                } else if (
                                    value.weWorkEmployeeConfig
                                        .newEmployeeSpecialDateConfig.diff > 0
                                ) {
                                    this.newCustomer = 1;
                                    this.specialDay =
                                        value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.diff;
                                } else {
                                    this.newCustomer = 0;
                                    this.specialDay = 1;
                                }
                            }
                            this.inductionDaysNumber =
                                value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.duration.num;
                            this.inductionDaysType =
                                value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.duration.timeUnit;
                            this.specialDateTime =
                                value.weWorkEmployeeConfig.newEmployeeSpecialDateConfig.time;
                        }
                    }
                    if (value.weWorkEmployeeConfig.periodic) {
                        this.periodicRule =
                            value.weWorkEmployeeConfig.periodic.rule;
                        if (
                            value.weWorkEmployeeConfig.periodic.weekConfig !==
                            null
                        ) {
                            this.setWeek =
                                value.weWorkEmployeeConfig.periodic.weekConfig.days;
                            this.checkTime =
                                value.weWorkEmployeeConfig.periodic.weekConfig.time;
                        }
                        if (
                            value.weWorkEmployeeConfig.periodic.dayConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.weWorkEmployeeConfig.periodic.dayConfig.time;
                        }
                        if (
                            value.weWorkEmployeeConfig.periodic.monthConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.weWorkEmployeeConfig.periodic.monthConfig.time;
                            this.setMonthTime =
                                value.weWorkEmployeeConfig.periodic.monthConfig.day;
                        }
                        if (
                            value.weWorkEmployeeConfig.periodic.yearConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.weWorkEmployeeConfig.periodic.yearConfig.time;
                            this.dataPicker =
                                value.weWorkEmployeeConfig.periodic.yearConfig.date;
                            this.GregorianOrLunar =
                                value.weWorkEmployeeConfig.periodic.yearConfig.type;
                        }
                    }
                } else if (value.dingTalkGroupConfig) {
                    if (value.dingTalkGroupConfig.sendTimeType) {
                        this.sendTimeType =
                            value.dingTalkGroupConfig.sendTimeType;
                    }
                    this.selectedAgentId = value.dingTalkGroupConfig.bindCode;
                    this.sendingRule = value.sendType;
                    setTimeout(() => {
                      if (this.agentIdDingGroupAgentInfo[this.selectedAgentId]){
                        this.loadBotInfo(this.agentIdDingGroupAgentInfo[this.selectedAgentId].agentId, this.agentIdDingGroupAgentInfo[this.selectedAgentId].corpId);
                      }
                    }, 500);
                    if (this.sendTimeType === "TIMED") {
                        if (value.dingTalkGroupConfig.timed.date) {
                            this.checkDateTime = value.dingTalkGroupConfig.timed.date;
                        }
                    }
                    if (value.dingTalkGroupConfig.periodic) {
                        this.periodicRule =
                            value.dingTalkGroupConfig.periodic.rule;
                        if (
                            value.dingTalkGroupConfig.periodic.weekConfig !==
                            null
                        ) {
                            this.setWeek =
                                value.dingTalkGroupConfig.periodic.weekConfig.days;
                            this.checkTime =
                                value.dingTalkGroupConfig.periodic.weekConfig.time;
                        }
                        if (
                            value.dingTalkGroupConfig.periodic.dayConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.dingTalkGroupConfig.periodic.dayConfig.time;
                        }
                        if (
                            value.dingTalkGroupConfig.periodic.monthConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.dingTalkGroupConfig.periodic.monthConfig.time;
                            this.setMonthTime =
                                value.dingTalkGroupConfig.periodic.monthConfig.day;
                        }
                        if (
                            value.dingTalkGroupConfig.periodic.yearConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.dingTalkGroupConfig.periodic.yearConfig.time;
                            this.dataPicker =
                                value.dingTalkGroupConfig.periodic.yearConfig.date;
                            this.GregorianOrLunar =
                                value.dingTalkGroupConfig.periodic.yearConfig.type;
                        }
                    }
                } else if (value.dingTalkAppConfig) {
                    if (value.dingTalkAppConfig.sendTimeType) {
                        this.sendTimeType =
                            value.dingTalkAppConfig.sendTimeType;
                    }
                    this.selectedAgentId = value.dingTalkAppConfig.bindCode;
                    this.sendingRule = value.sendType;
                    if (value.dingTalkAppConfig.originSelectIds) {
                        this.originSelectIds = value.dingTalkAppConfig.originSelectIds;
                        /*setTimeout(() => {
                            this.loadDingMemberInfo(this.agentIdDingAgentInfo[this.selectedAgentId].corpId, this.originSelectIds);
                        }, 500);*/

                    }
                    setTimeout(() => {
                      if (this.agentIdDingAgentInfo[this.selectedAgentId]){
                        this.loadBotInfo(this.agentIdDingAgentInfo[this.selectedAgentId].agentId, this.agentIdDingAgentInfo[this.selectedAgentId].corpId);
                        this.selectedAgentInfo = this.agentIdDingAgentInfo[this.selectedAgentId];
                      }
                    }, 500);
                    if (value.dingTalkAppConfig.extInfo && value.dingTalkAppConfig.extInfo.userTag) {
                        this.employeeConfigTag = value.dingTalkAppConfig.extInfo.userTag;
                        this.userTags = value.dingTalkAppConfig.extInfo.userTag;
                    }
                    if (value.sendType === "BIRTHDAY") {
                        if (
                            value.dingTalkAppConfig.birthDayDateConfig
                        ) {
                            this.specialDateTime =
                                value.dingTalkAppConfig.birthDayDateConfig.time;
                        }
                    }
                    if (value.sendType === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
                        if (
                            value.dingTalkAppConfig
                                .newEmployeeSpecialDateConfig
                        ) {
                            if (
                                value.dingTalkAppConfig
                                    .newEmployeeSpecialDateConfig.diff
                            ) {
                                if (
                                    value.dingTalkAppConfig
                                        .newEmployeeSpecialDateConfig.diff < 0
                                ) {
                                    this.newCustomer = -1;
                                    this.specialDay =
                                        -value.dingTalkAppConfig
                                            .newEmployeeSpecialDateConfig.diff;
                                } else if (
                                    value.dingTalkAppConfig
                                        .newEmployeeSpecialDateConfig.diff > 0
                                ) {
                                    this.newCustomer = 1;
                                    this.specialDay =
                                        value.dingTalkAppConfig.newEmployeeSpecialDateConfig.diff;
                                } else {
                                    this.newCustomer = 0;
                                    this.specialDay = 1;
                                }
                            }
                            this.inductionDaysNumber =
                                value.dingTalkAppConfig.newEmployeeSpecialDateConfig.duration.num;
                            this.inductionDaysType =
                                value.dingTalkAppConfig.newEmployeeSpecialDateConfig.duration.timeUnit;
                            this.specialDateTime =
                                value.dingTalkAppConfig.newEmployeeSpecialDateConfig.time;
                        }
                    }
                    if (this.sendTimeType === "TIMED") {
                        if (value.dingTalkAppConfig.timed.date) {
                            this.checkDateTime = value.dingTalkAppConfig.timed.date;
                        }
                    }
                    if (value.dingTalkAppConfig.periodic) {
                        this.periodicRule =
                            value.dingTalkAppConfig.periodic.rule;
                        if (
                            value.dingTalkAppConfig.periodic.weekConfig !==
                            null
                        ) {
                            this.setWeek =
                                value.dingTalkAppConfig.periodic.weekConfig.days;
                            this.checkTime =
                                value.dingTalkAppConfig.periodic.weekConfig.time;
                        }
                        if (
                            value.dingTalkAppConfig.periodic.dayConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.dingTalkAppConfig.periodic.dayConfig.time;
                        }
                        if (
                            value.dingTalkAppConfig.periodic.monthConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.dingTalkAppConfig.periodic.monthConfig.time;
                            this.setMonthTime =
                                value.dingTalkAppConfig.periodic.monthConfig.day;
                        }
                        if (
                            value.dingTalkAppConfig.periodic.yearConfig !==
                            null
                        ) {
                            this.checkTime =
                                value.dingTalkAppConfig.periodic.yearConfig.time;
                            this.dataPicker =
                                value.dingTalkAppConfig.periodic.yearConfig.date;
                            this.GregorianOrLunar =
                                value.dingTalkAppConfig.periodic.yearConfig.type;
                        }
                    }
                } else if (value.yunzhijiaConfig){
                  if (value.yunzhijiaConfig.sendTimeType) {
                    this.sendTimeType =
                        value.yunzhijiaConfig.sendTimeType;
                  }
                  this.selectedAgentId = value.yunzhijiaConfig.bindCode;
                  this.sendingRule = value.sendType;
                  if (value.yunzhijiaConfig.originSelectIds) {
                    this.originSelectIds = value.yunzhijiaConfig.originSelectIds;
                  }
                  setTimeout(() => {
                    if (this.agentIdYunzhijiaAgentInfo[this.selectedAgentId]){
                      this.loadBotInfo(this.agentIdYunzhijiaAgentInfo[this.selectedAgentId].agentId, this.agentIdYunzhijiaAgentInfo[this.selectedAgentId].corpId);
                      this.selectedAgentInfo = this.agentIdYunzhijiaAgentInfo[this.selectedAgentId];
                    }
                  }, 500);
                  if (value.yunzhijiaConfig.extInfo && value.yunzhijiaConfig.extInfo.userTag) {
                    this.employeeConfigTag = value.yunzhijiaConfig.extInfo.userTag;
                    this.userTags = value.yunzhijiaConfig.extInfo.userTag;
                  }
                  if (value.sendType === "BIRTHDAY") {
                    if (
                        value.yunzhijiaConfig.birthDayDateConfig
                    ) {
                      this.specialDateTime =
                          value.yunzhijiaConfig.birthDayDateConfig.time;
                    }
                  }
                  if (value.sendType === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
                    if (
                        value.yunzhijiaConfig
                            .newEmployeeSpecialDateConfig
                    ) {
                      if (
                          value.yunzhijiaConfig
                              .newEmployeeSpecialDateConfig.diff
                      ) {
                        if (
                            value.yunzhijiaConfig
                                .newEmployeeSpecialDateConfig.diff < 0
                        ) {
                          this.newCustomer = -1;
                          this.specialDay =
                              -value.yunzhijiaConfig
                                  .newEmployeeSpecialDateConfig.diff;
                        } else if (
                            value.yunzhijiaConfig
                                .newEmployeeSpecialDateConfig.diff > 0
                        ) {
                          this.newCustomer = 1;
                          this.specialDay =
                              value.yunzhijiaConfig.newEmployeeSpecialDateConfig.diff;
                        } else {
                          this.newCustomer = 0;
                          this.specialDay = 1;
                        }
                      }
                      this.inductionDaysNumber =
                          value.yunzhijiaConfig.newEmployeeSpecialDateConfig.duration.num;
                      this.inductionDaysType =
                          value.yunzhijiaConfig.newEmployeeSpecialDateConfig.duration.timeUnit;
                      this.specialDateTime =
                          value.yunzhijiaConfig.newEmployeeSpecialDateConfig.time;
                    }
                  }
                  if (this.sendTimeType === "TIMED") {
                    if (value.yunzhijiaConfig.timed.date) {
                      this.checkDateTime = value.yunzhijiaConfig.timed.date;
                    }
                  }
                  if (value.yunzhijiaConfig.periodic) {
                    this.periodicRule =
                        value.yunzhijiaConfig.periodic.rule;
                    if (
                        value.yunzhijiaConfig.periodic.weekConfig !==
                        null
                    ) {
                      this.setWeek =
                          value.yunzhijiaConfig.periodic.weekConfig.days;
                      this.checkTime =
                          value.yunzhijiaConfig.periodic.weekConfig.time;
                    }
                    if (
                        value.yunzhijiaConfig.periodic.dayConfig !==
                        null
                    ) {
                      this.checkTime =
                          value.yunzhijiaConfig.periodic.dayConfig.time;
                    }
                    if (
                        value.yunzhijiaConfig.periodic.monthConfig !==
                        null
                    ) {
                      this.checkTime =
                          value.yunzhijiaConfig.periodic.monthConfig.time;
                      this.setMonthTime =
                          value.yunzhijiaConfig.periodic.monthConfig.day;
                    }
                    if (
                        value.yunzhijiaConfig.periodic.yearConfig !==
                        null
                    ) {
                      this.checkTime =
                          value.yunzhijiaConfig.periodic.yearConfig.time;
                      this.dataPicker =
                          value.yunzhijiaConfig.periodic.yearConfig.date;
                      this.GregorianOrLunar =
                          value.yunzhijiaConfig.periodic.yearConfig.type;
                    }
                  }
                }
            },
            //切换入职特殊日期的类型
            changeInductionDay(type) {
                this.inductionDaysNumber = 1;
            },
            goBack() {
                this.$router.go(-1);
            },
            headerTab(item) {
                this.headerList.forEach(head => {
                    this.$set(head, "isActive", false);
                });
                this.$set(item, "isActive", true);
                this.activeHeader = item.name;
            },
            publishTask() {
                let originSelectIds = [];
                let employeeSelectedConfigs = [];
                let tags = [];
                let taskInfo = {
                    attachmentList: [],
                    channel: this.channelType,
                    crowd: this.selectedGroupType,
                    name: this.taskName,
                    sendType: "",
                    text: this.welcomeConfig.text,
                    version: "202203-V3",
                };
                let timed = {};
                if (this.sendTimeType == "TIMED") {
                    timed.date = this.checkDateTime;
                }
                let periodicConfig = {};
                if (this.sendTimeType === "PERIODIC") {
                    periodicConfig.rule =
                        this.periodicRule;
                    if (this.periodicRule === "DAY") {
                        periodicConfig.dayConfig = {
                            time: this.checkTime,
                        };
                    }
                    if (this.periodicRule === "WEEK") {
                        periodicConfig.weekConfig = {
                            days: this.setWeek,
                            time: this.checkTime,
                        };
                    }
                    if (this.periodicRule === "MOUTH") {
                        periodicConfig.monthConfig = {
                            day: this.setMonthTime,
                            time: this.checkTime,
                        };
                    }
                    if (this.periodicRule === "YEAR") {
                        periodicConfig.yearConfig = {
                            date: this.dataPicker,
                            time: this.checkTime,
                            type: this.GregorianOrLunar,
                        };
                    }
                }
                taskInfo.sendType = this.sendingRule;
                if (this.channelType === "WE_WORK") {
                    originSelectIds = this.employeeConfigTag.length > 0 ? this.employeeConfigTag.map(value => String(value.id)) : [];
                    employeeSelectedConfigs = this.employeeConfigTag.length > 0 ? this.employeeConfigTag.map(value => {
                        let info = {
                            type: value.isMember || value.type == 0 ? "MEMBER" : "DEPARTMENT",
                        };
                        if (this.selectedAgentInfo.bindType===0&&this.selectedAgentInfo.wechatType===0){
                          info.id = value.label
                        }
                        else {
                          info.id = value.id
                        }
                        return info;
                    }) : [];
                    taskInfo.weWorkEmployeeConfig = {
                        sendType: this.sendingRule,
                        weWorkAgentId: this.selectedAgentId,
                        weWorkAgentSecret: this.selectedSecret,
                        cropId: this.selectedAgentInfo.corpId,
                        agentName: this.selectedAgentInfo.agentName,
                        originSelectIds: originSelectIds,
                        employeeSelectedConfigs: employeeSelectedConfigs,
                        version: 1,
                        sendTimeType: this.sendTimeType,
                        timed: timed,
                        periodic: periodicConfig,
                        bindCode: this.selectedAgentInfo.bindCode,
                    };
                    if (this.sendingRule === "BIRTHDAY") {
                        taskInfo.weWorkEmployeeConfig.birthDayDateConfig = {
                            time: this.specialDateTime,
                        };
                    }
                    if (this.sendingRule === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
                        taskInfo.weWorkEmployeeConfig.newEmployeeSpecialDateConfig =
                            {
                                diff: this.newCustomer * this.specialDay,
                                duration: {
                                    num: Number(this.inductionDaysNumber),
                                    timeUnit: this.inductionDaysType,
                                },
                                time: this.specialDateTime,
                            };
                    }

                } else if (this.channelType === "DING_TALK") {
                    originSelectIds = this.employeeConfigTag.length > 0 ? this.employeeConfigTag.map(value => value.id) : [];
                    employeeSelectedConfigs = this.employeeConfigTag.length > 0 ? this.employeeConfigTag.map(value => {
                        let info = {
                            type: !value.type ? "MEMBER" : "DEPARTMENT",
                            id: value.id,
                        };
                        return info;
                    }) : [];
                    taskInfo.dingTalkAppConfig = {
                        sendTimeType: this.sendTimeType,
                        timed: timed,
                        periodic: periodicConfig,
                        bindCode: this.selectedAgentId,
                        extInfo: {
                            dingTalkName: this.selectedAgentInfo.agentName,
                            userTag: this.employeeConfigTag,
                        },
                        originSelectIds: originSelectIds,
                        employeeSelectedConfigs: employeeSelectedConfigs,
                        sendType: this.sendingRule,
                    };
                    if (this.sendingRule === "BIRTHDAY") {
                        taskInfo.dingTalkAppConfig.birthDayDateConfig = {
                            time: this.specialDateTime,
                        };
                    }
                    if (this.sendingRule === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
                        taskInfo.dingTalkAppConfig.newEmployeeSpecialDateConfig =
                            {
                                diff: this.newCustomer * this.specialDay,
                                duration: {
                                    num: Number(this.inductionDaysNumber),
                                    timeUnit: this.inductionDaysType,
                                },
                                time: this.specialDateTime,
                            };
                    }
                } else if (this.channelType === "DING_TALK_GROUP") {
                    taskInfo.dingTalkGroupConfig = {
                        sendTimeType: this.sendTimeType,
                        timed: timed,
                        periodic: periodicConfig,
                        bindCode: this.selectedAgentId,
                        extInfo: {
                            dingTalkGroupName: this.selectedAgentInfo.agentName,
                        },
                    };
                } else if (this.channelType === "YUN_ZHI_JIA") {
                  originSelectIds = this.employeeConfigTag.length > 0 ? this.employeeConfigTag.map(value => value.id) : [];
                  employeeSelectedConfigs = this.employeeConfigTag.length > 0 ? this.employeeConfigTag.map(value => {
                    let info = {
                      type: !value.type ? "MEMBER" : "DEPARTMENT",
                      id: value.id,
                    };
                    return info;
                  }) : [];
                  taskInfo.yunzhijiaConfig = {
                    sendTimeType: this.sendTimeType,
                    timed: timed,
                    periodic: periodicConfig,
                    bindCode: this.selectedAgentId,
                    extInfo: {
                      yunzhijiaAgentName: this.selectedAgentInfo.agentName?this.selectedAgentInfo.agentName:"",
                      userTag: this.employeeConfigTag,
                    },
                    originSelectIds: originSelectIds,
                    employeeSelectedConfigs: employeeSelectedConfigs,
                    sendType: this.sendingRule,
                  };
                  if (this.sendingRule === "BIRTHDAY") {
                    taskInfo.yunzhijiaConfig.birthDayDateConfig = {
                      time: this.specialDateTime,
                    };
                  }
                  if (this.sendingRule === "SPECIAL_DATE_FOR_NEW_EMPLOYEE") {
                    taskInfo.yunzhijiaConfig.newEmployeeSpecialDateConfig =
                        {
                          diff: this.newCustomer * this.specialDay,
                          duration: {
                            num: Number(this.inductionDaysNumber),
                            timeUnit: this.inductionDaysType,
                          },
                          time: this.specialDateTime,
                        };
                  }
                }
                if (!this.taskName) {
                    this.$message({
                        message: "任务名称不能为空",
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (!this.selectedAgentId && (this.channelType === "WE_WORK" || this.channelType === "DING_TALK")) {
                    this.$message({
                        message: "请选择发送应用",
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.employeeConfigTag.length === 0 && (this.channelType === "WE_WORK" || this.channelType === "DING_TALK")) {
                    this.$message({
                        message: "请选择发送范围",
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (!this.selectedAgentId && this.channelType === "DING_TALK_GROUP") {
                    this.$message({
                        message: "请选择发送范围",
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (
                    this.selectedGroupType === "CUSTOMER" &&
                    this.customerScopeName === "TAG" &&
                    tags.length === 0
                ) {
                    this.$message({
                        message: "请选择标签",
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.sendTimeType === "TIMED") {
                    let time = true;
                    if (!this.checkDateTime) {
                        this.$message({
                            message: "请选择时间",
                            duration: 2000,
                            type: "warning",
                        });
                        return (time = false);
                    }
                    if (!time) {
                        return;
                    }
                }
                if (this.sendTimeType === "PERIODIC") {
                    let time = true;
                    if (this.periodicRule === "WEEK") {
                        if (this.setWeek.length === 0) {
                            this.$message({
                                message: "请选择重复时间",
                                duration: 2000,
                                type: "warning",
                            });
                            return (time = false);
                        }
                    }
                    if (this.periodicRule === "YEAR") {
                        if (!this.dataPicker) {
                            this.$message({
                                message: "请选择日期",
                                duration: 2000,
                                type: "warning",
                            });
                            return (time = false);
                        }
                    }
                    if (!this.checkTime) {
                        this.$message({
                            message: "请选择时间",
                            duration: 2000,
                            type: "warning",
                        });
                        return (time = false);
                    }
                    if (!time) {
                        return;
                    }
                }
                let attachmentList = [];
                let isBool = false;
                let isYunzhijia = false;
                //遍历处理发送内容，内容为空的删除
                this.welcomeConfig.attachmentList.forEach(item => {
                    if (item.msgtype === "text") {
                        if (item.text.content) {
                            attachmentList.push(item);
                        }
                    } else if (item.msgtype === "image" ||
                        item.msgtype === "video" ||
                        item.msgtype === "file") {
                        if (item[item.msgtype].url) {
                            attachmentList.push(item);
                        }
                    } else if (item.msgtype === "messagecard" ||
                        item.msgtype === "weathercard" ||
                        item.msgtype === "datacard") {
                        if (item[item.msgtype].content) {
                            let btnList = [];
                            item[item.msgtype].btnList.forEach(btn => {
                                if (btn.link.url) {
                                    btnList.push(btn);
                                }
                            });
                            item[item.msgtype].btnList = btnList;
                            attachmentList.push(item);
                        }
                    } else if (item.msgtype === "botcard") {
                        if (item[item.msgtype].title &&
                            item[item.msgtype].picurl &&
                            item[item.msgtype].desc &&
                            item[item.msgtype].initIntentId &&
                            item[item.msgtype].apiKey) {
                            attachmentList.push(item);
                        }
                    } else if (item.msgtype === "link") {
                        if (item[item.msgtype].title &&
                            item[item.msgtype].picurl &&
                            item[item.msgtype].desc &&
                            item[item.msgtype].content) {
                            attachmentList.push(item);
                        }
                    } else if (item.msgtype === "external_link") {
                        if (item.externalLink.title &&
                            item.externalLink.picurl &&
                            item.externalLink.desc &&
                            item.externalLink.url) {
                            attachmentList.push(item);
                        }
                    }
                });
                console.debug("taskInfo", taskInfo);
                taskInfo.attachmentList = attachmentList;
                if (taskInfo.attachmentList.length === 0) {
                    this.$message({
                        message: "请至少有一项发送内容",
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                taskInfo.attachmentList.forEach(item => {
                    if (item.msgtype === "messagecard" ||
                        item.msgtype === "weathercard" ||
                        item.msgtype === "datacard") {
                        if (item[item.msgtype].btnList.length > 3 && (this.channelType === "DING_TALK" || this.channelType === 'DING_TALK_GROUP')) {
                            isBool = true;
                        }
                        if (item[item.msgtype].btnList.length > 1 && this.channelType === 'YUN_ZHI_JIA'){
                          isYunzhijia = true
                        }
                    }
                });
                if (isBool) {
                    this.$message({
                        message: "钉钉应用卡片按钮不可超过三个",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (isYunzhijia){
                  this.$message({
                    message: "云之家应用卡片按钮不可超过一个",
                    type: "warning",
                    duration: 2000,
                  });
                  return;
                }
                let id = this.$route.query.taskId;
                if (id) {
                    let url = "/scrm-api/group-send-message-task/";
                    console.log(taskInfo);
                    this.FetchPut(url, id, taskInfo).then((res) => {
                        this.$message({
                            message: "更新任务成功",
                            duration: 2000,
                            type: "success",
                        });
                        this.goBack();
                    });
                } else {
                    let url = "/scrm-api/group-send-message-task";

                    this.FetchPost(url, taskInfo).then((res) => {
                        this.$message({
                            message: "新增任务成功",
                            duration: 2000,
                            type: "success",
                        });
                        this.goBack();
                    });
                }
            },
            onNodeClick(node) {
              console.debug('this.sendingRule',this.sendingRule,node)
              if ((this.sendingRule==='NEW_EMPLOYEE'&&(!node.isMember&&node.type==1))||this.sendingRule!=='NEW_EMPLOYEE'){
                this.$set(node,'checked',!node.checked)
              }
                if (node.checked) {
                    this.userTags.push(node);
                } else {
                    for (let i = 0; i < this.userTags.length; i++) {
                        if (this.userTags[i].id === node.id) {
                            this.userTags.splice(i, 1);
                        }
                    }
                }
                this.userTags = [...this.userTags];
                console.log(this.userTags);
            },
            loadMoreMember(node) {
                let data = node.parent ? node.parent.data : "";
                if (data) {
                    this.loadMemberList(data);
                }
            },
            loadMemberList(data, type) {
                let url = "/portal-api/member/list/" + data.id + "?page=1&size=20";
                url += "&onlyMainDept=true";
                this.FetchGet(url).then(res => {
                    console.debug("res", res);
                    let memberList = [];
                    if (data.children.length > 0) {
                        if (data.children[data.children.length - 1].isNotLabel) {
                            data.children.splice(data.children.length - 1, 1);
                        }
                    }
                    if (res.code == "0") {
                        memberList = res.data.list;
                        memberList = memberList.map(item => {
                            this.$set(item, "label", item.name);
                            this.$set(item, "checked", false);
                            if (this.originSelectIds.indexOf(String(item.id)) !== -1) {
                                this.$set(item, "checked", true);
                            }
                            return item;
                        });
                        let info = {
                            isNotLabel: true,
                            label: "加载更多",
                            isLastPage: res.data.isLastPage,
                        };
                        if (!res.data.isLastPage) {
                            memberList.push(info);
                        }
                        data.children = data.children.concat(memberList);
                    }
                });
            },
            confirmMember() {
                this.employeeConfigTag = [];
                this.employeeConfigTag = this.userTags;
                if (this.selectedAgentInfo.bindType === 0 && this.selectedAgentInfo.wechatType === 0) {
                    WWOpenData.bind(this.$el);
                }
                this.memberDialogTableVisible = false;
                this.memberDialogDingTableVisible = false;
            },
            loadAgentList() {
                //agentId2AgentInfo
                this.FetchGet("/portal-api/we-work/agent-info").then((res) => {
                    console.log("发送的应用", res);
                    this.sendingAgentList = res.filter((agent) => agent.wechatType === 0);
                    if (this.sendingAgentList && this.sendingAgentList.length > 0) {
                        for (let i = 0, len = this.sendingAgentList.length; i < len; i++) {
                            let agentInfo = this.sendingAgentList[i];
                            this.agentId2AgentInfo[agentInfo.agentId] = agentInfo;
                        }
                    }
                });
            },
            //获取钉钉群
            getDingTalkGroup() {
                let url = "/api/channel/access/list/6";
                this.FetchGet(url).then(res => {
                    if (res.code === "0" && res.data) {
                        this.dingTalkGroup = res.data;
                        if (this.dingTalkGroup && this.dingTalkGroup.length > 0) {
                            for (let i = 0, len = this.dingTalkGroup.length; i < len; i++) {
                                let agentInfo = this.dingTalkGroup[i];
                                this.agentIdDingGroupAgentInfo[agentInfo.bindCode] = agentInfo;
                            }
                        }
                    }
                });
            },
            //获取钉钉应用
            getDingTalk() {
                //agentIdDingAgentInfo
                let url = "/api/channel/access/list/3";
                this.FetchGet(url).then(res => {
                    if (res.code === "0" && res.data) {
                        this.dingTalkList = res.data;
                        if (this.dingTalkList && this.dingTalkList.length > 0) {
                            for (let i = 0, len = this.dingTalkList.length; i < len; i++) {
                                let agentInfo = this.dingTalkList[i];
                                this.agentIdDingAgentInfo[agentInfo.bindCode] = agentInfo;
                            }
                        }
                    }
                });
            },
            //获取云之家应用
            getYunzhijiaList(){
              let url = "api/channel/access/list/13";
              this.FetchGet(url).then(res=>{
                if (res.code == '0' && res.data){
                  this.yunzhijiaList = res.data;
                  if (this.yunzhijiaList.length > 0){
                    for (let i =0;i<this.yunzhijiaList.length;i++){
                      let agentInfo = this.yunzhijiaList[i];
                      this.agentIdYunzhijiaAgentInfo[agentInfo.bindCode] = agentInfo;
                    }
                  }
                }
              })
            },
            channelValueChange(value) {
                if (this.welcomeConfig.attachmentList.length > 0) {
                    this.channelType = this.checkBeforeChannelType;
                    this.$confirm("切换渠道后发送内容将清空?", "是否切换渠道", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        customClass: "changeChannel",
                    }).then(() => {
                        this.channelType = value;
                        this.checkBeforeChannelType = value;
                        this.isSelectedChannelDisable = false;
                        this.sendingRule = "GENERAL";
                        this.sendTimeType = "NOW";
                        this.periodicRule = "DAY";
                        this.selectedAgentId = "";
                        this.welcomeConfig.attachmentList = [];
                        this.clearUserTag();
                        this.$refs.sendContent.showVariable = false
                        if (value == "WE_WORK") {
                            this.elSelectPlaceholder = "请选择企业微信应用";
                        } else if (value == "DING_TALK") {
                            this.elSelectPlaceholder = "请选择钉钉应用";
                        } else if (value == "DING_TALK_GROUP") {
                            this.elSelectPlaceholder = "请选择钉钉群";
                        }
                    }).catch(() => {
                        this.channelType = this.checkBeforeChannelType;
                    });
                } else {
                    this.channelType = value;
                    this.checkBeforeChannelType = value;
                    this.isSelectedChannelDisable = false;
                    this.sendingRule = "GENERAL";
                    this.sendTimeType = "NOW";
                    this.periodicRule = "DAY";
                    this.selectedAgentId = "";
                    this.welcomeConfig.attachmentList = [];
                    this.clearUserTag()
                    if (value == "WE_WORK") {
                        this.elSelectPlaceholder = "请选择企业微信应用";
                    } else if (value == "DING_TALK") {
                        this.elSelectPlaceholder = "请选择钉钉应用";
                    } else if (value == "DING_TALK_GROUP") {
                        this.elSelectPlaceholder = "请选择钉钉群";
                    }
                }
            },

            changeSendAgentInfo(agentId) {
                this.clearUserTag()
                this.selectedAgentId = this.agentId2AgentInfo[agentId].agentId;
                this.selectedAgentInfo = this.agentId2AgentInfo[agentId];
                this.selectedSecret = this.agentId2AgentInfo[agentId].secret;
                console.debug("agent info changed", this.selectedAgentInfo);
                this.loadMemberInfo(this.agentId2AgentInfo[agentId].corpId, this.agentId2AgentInfo[agentId].agentId, this.agentId2AgentInfo[agentId].secret, "", this.agentId2AgentInfo[agentId].thirdId, this.agentId2AgentInfo[agentId].bindType);
                this.loadBotInfo(this.agentId2AgentInfo[agentId].agentId, this.agentId2AgentInfo[agentId].corpId);

            },
            changeSendAgentDing(value) {
                if (value) {
                    if (this.channelType === "DING_TALK") {
                        this.clearUserTag()
                        this.selectedAgentInfo = this.agentIdDingAgentInfo[value];
                        /*this.loadDingMemberInfo(this.agentIdDingAgentInfo[value].corpId);*/
                        this.loadBotInfo(this.agentIdDingAgentInfo[value].agentId, this.agentIdDingAgentInfo[value].corpId);

                    } else if (this.channelType === 'YUN_ZHI_JIA'){
                        this.clearUserTag();
                        this.selectedAgentInfo = this.agentIdYunzhijiaAgentInfo[value];
                        this.loadBotInfo(this.agentIdYunzhijiaAgentInfo[value].agentId, this.agentIdYunzhijiaAgentInfo[value].corpId);
                    } else {
                        this.dingTalkGroup.forEach(item => {
                            if (item.bindCode === value) {
                                this.selectedAgentInfo.agentName = item.agentName;
                            }
                        });
                        this.loadBotInfo(this.agentIdDingGroupAgentInfo[value].agentId, this.agentIdDingGroupAgentInfo[value].corpId);
                    }

                }
            },
            clearUserTag(){
                this.userTags = [];
                this.employeeConfigTag = [];
                this.memberList.forEach((item) => {
                    this.$set(item, "checked", false);
                    this.setChecked(item.children);
                });
            },
            loadBotInfo(agentId, corpId) {
                this.agentBotInfos = [];
                let url = "/scrm-api/intermediate/bot-infos-agent?agentId=" + agentId + "&corpId=" + corpId;
                this.FetchGet(url).then((res) => {
                    console.debug("load agent bot infos", res);
                    this.agentBotInfos = res;
                });
            },
            loadMemberInfo(corpId, agentId, secret, userIds, thirdId, bindType) {
              console.debug('userIds',userIds)
              /*if (userIds&&!(this.selectedAgentInfo.bindType===0&&this.selectedAgentInfo.wechatType===0)){
                this.getoriginSelectMember(userIds)
              }*/
              if (userIds&&this.selectedAgentInfo.bindType===0&&this.selectedAgentInfo.wechatType===0){
                this.employeeConfigTag = userIds.map(item=>{
                  let info = {
                    type:item.type==='DEPARTMENT'?1:0,
                    label:item.id,
                    id:item.id
                  }
                  return info
                });
                let obj = {};
                let peon = this.employeeConfigTag.reduce((cur, next) => {
                  obj[next.label] ? "" : obj[next.label] = true && cur.push(next);
                  return cur;
                }, []);
                this.employeeConfigTag = peon;
                this.userTags = peon
              }
              if (this.channelType === "WE_WORK") {
                console.debug("selectedAgentInfo", this.selectedAgentInfo);
                if (!(this.selectedAgentInfo.bindType == 0 && this.selectedAgentInfo.wechatType == 0)) {
                  this.sendingRulesList = this.sendingFilterRules;
                }
              }
               /* this.memberList = [];
                let url = "/scrm-api/we-work/member-agent?agentId=" + agentId + "&secret=" + secret + "&corpId=" + corpId + "&thirdId=" + thirdId + "&bindType=" + bindType;
                this.FetchGet(url).then((res) => {
                    if (res !== "") {
                        this.memberList = res;
                        console.debug('memberList',this.memberList)
                        this.memberList.forEach((item) => {
                            this.$set(item, "checked", false);
                            this.setChecked(item.children);
                        });
                    }
                    if (userIds) {
                        this.seachSelectUserIds(
                            this.memberList,
                            userIds,
                        );
                        let obj = {};
                        let peon = this.employeeConfigTag.reduce((cur, next) => {
                            obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
                            return cur;
                        }, []);
                        this.employeeConfigTag = peon;
                        this.userTags = peon;
                        if (this.selectedAgentInfo.bindType === 0 && this.selectedAgentInfo.wechatType === 0) {
                            WWOpenData.bind(this.$el);
                        }
                    }

                });
                if (this.channelType === "WE_WORK") {
                    console.debug("selectedAgentInfo", this.selectedAgentInfo);
                    if (!(this.selectedAgentInfo.bindType == 0 && this.selectedAgentInfo.wechatType == 0)) {
                        this.sendingRulesList = this.sendingFilterRules;
                    }
                }*/
            },
          //接口查询选择的员工
          async getoriginSelectMember(userIds){
            let memberIds = [];
            let departmembentIds = [];
            userIds.forEach(item=>{
              if (item.type == 'MEMBER'){
                memberIds.push(item.id)
              }
              else {
                departmembentIds.push(Number(item.id))
              }
            })
              let departmentUrl = "/portal-api/department/list-by-ref-ids?corpId="+this.selectedAgentInfo.corpId;
              let memberUrl = "/portal-api/member/list-by-user-ids?corpId="+this.selectedAgentInfo.corpId;
            departmembentIds.forEach((item,index)=>{
              departmentUrl+=index==0?'&ids='+item:','+item
            })
            memberIds.forEach((item,index)=>{
              memberUrl+=index==0?'&ids='+item:','+item
            })
            let userTags = []
            if (departmembentIds.length > 0){
             await this.FetchGet(departmentUrl).then(res=>{
                if (res.code == '0'&&res.data){
                  let departmentData = res.data.map(item=>{
                    let info = {
                      type:1,
                      label:item.name,
                      id:item.id
                    }
                    return info
                  });
                  userTags = departmentData;
                }
              })
            }
            if (memberIds.length > 0){
             await this.FetchGet(memberUrl).then(res=>{
                if (res.code == '0'&&res.data)
                {
                  let memberData = res.data.map(item=>{
                    let info = {
                      type:0,
                      label:item.name,
                      id:item.id
                    }
                    return info
                  });
                  userTags = userTags.concat(memberData)
                }
              })
            }
            let obj = {};
            let peon = userTags.reduce((cur, next) => {
              obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
              return cur;
            }, []);
            this.employeeConfigTag = peon;
            this.userTags = peon;
            console.debug('employeeConfigTag',this.employeeConfigTag)
          },
          //懒加载企微员工
            loadNode(node, resolve) {
              /*let userId =  localStorage.getItem('_uid');
              let ids = this.employeeConfigTag.map(item=>{
                return item.id
              })
              if (node.level === 0) {
                let datas = [];
                this.weWorkNode = node;
                this.weWorkResolve = resolve;
                let url = this.requestUrl.department.corpListLazy+'?userId='+userId
                this.FetchGet(url).then(res => {
                  datas = res || [];
                  this.memberList = this.handlerCheckedSelf(datas,ids);
                  datas.forEach(element => {
                    if(element.type === 0) {
                      element.leaf = true
                    }
                  })
                  return resolve(datas);

                });
                /!* return resolve([{ name: 'region' }]);*!/
              }
              if (node.level >= 1){
                let datas = [];
                let url = this.requestUrl.department.corpListLazy+ '?departId=' + node.data.id + "&userId=" + userId
                this.FetchGet(url)
                    .then((res) => {
                      datas = res || [];
                      datas.forEach(element => {
                        if(element.type === 0) {
                          element.leaf = true
                        }
                      })
                      let newDatas = this.handlerCheckedSelf(datas,ids)
                      this.memberList.forEach(item=>{
                        if (item.id === node.data.id){
                          item.children = newDatas
                        }
                      })
                      return resolve(datas);
                    });
              }*/
              let userId =  localStorage.getItem('_uid');
              let ids = this.employeeConfigTag.map(item=>{
                return item.id
              })
              console.debug('node',node)
              if (node.level === 0) {
                let datas = [];
                this.dingTalkNode = node;
                this.dingTalkResolve = resolve;
                let url = '/portal-api/department/corpListLazy-v2?' + 'userId=' + userId + '&corpId=' + this.selectedAgentInfo.corpId  + '&keyword=' + '' + '&page=' + this.currentPage + '&size=' + this.pageSize
                this.FetchGet(url).then(res => {
                  console.debug('res',res)
                  datas = res.list ? res.list : [];
                  this.memberList = this.handlerCheckedSelf(datas,ids);
                  datas.forEach(element => {
                    if(element.type === 0) {
                      element.leaf = true
                    }
                  })
                  return resolve(datas);
                });
              }
              if (node.level >= 1){
                let datas = [];
                let url = '/portal-api/department/corpListLazy-v2'+'?departId='+ node.data.id+ '&userId=' + userId + '&corpId=' + this.selectedAgentInfo.corpId  + '&keyword=' + '' + '&page=' + this.currentPage + '&size=' + this.pageSize
                this.FetchGet(url).then((res) => {
                  datas = res && res.list ? res.list : [];
                  if (res.hasNextPage){
                    let info = {
                      label:"加载更多",
                      dataType:'hasNext',
                      id:node.data.id + 'hasNext',
                      parentId:node.data.id,
                      currentPage:res.nextPage?res.nextPage:1
                    }
                    datas = datas.concat([info])
                  }
                  datas.forEach(element => {
                    if(element.type === 0 || element.dataType === 'hasNext') {
                      element.leaf = true
                    }
                  })
                  let newDatas = this.handlerCheckedSelf(datas,ids)
                  this.memberList.forEach(item=>{
                    if (item.id === node.data.id){
                      item.children = newDatas
                    }
                  })
                  node.data.children = newDatas
                  return resolve(newDatas);
                });
              }
            },
            handlerCheckedSelf(datas,ids,parentId){
              console.debug('datas',datas,ids)
              datas.forEach(item => {
                item.checked = false;
                if(ids.indexOf(item.id) !==-1 || ids.indexOf(item.label) !==-1){
                  // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
                  item.checked = true;
                }

              })
              return datas;
            },
          //懒加载钉钉应用下部门员工
          loadDingNode(node,resolve){
            let userId =  localStorage.getItem('_uid');
            let ids = this.employeeConfigTag.map(item=>{
              return item.id
            })
            if (node.level === 0) {
              let datas = [];
              this.dingTalkNode = node;
              this.dingTalkResolve = resolve;
              let url = '/portal-api/department/corpListLazy-v2?' + 'userId=' + userId + '&corpId=' + this.selectedAgentInfo.corpId  + '&keyword=' + '' + '&page=' + this.currentPage + '&size=' + this.pageSize
              this.FetchGet(url).then(res => {
                console.debug('res',res)
                datas = res.list ? res.list : [];
                this.memberList = this.handlerCheckedSelf(datas,ids);
                datas.forEach(element => {
                  if(element.type === 0) {
                    element.leaf = true
                  }
                })
                return resolve(datas);

              });
            }
            if (node.level >= 1){
              let datas = [];
              let url = '/portal-api/department/corpListLazy-v2'+'?departId='+ node.data.id+ '&userId=' + userId + '&corpId=' + this.selectedAgentInfo.corpId  + '&keyword=' + '' + '&page=' + this.currentPage + '&size=' + this.pageSize
              this.FetchGet(url).then((res) => {
                console.debug('datas',res)
                datas = res && res.list ? res.list : [];
                datas.forEach(element => {
                  if(element.type === 0) {
                    element.leaf = true
                  }
                })
                let newDatas = this.handlerCheckedSelf(datas,ids)
                this.memberList.forEach(item=>{
                  if (item.id === node.data.id){
                    item.children = newDatas
                  }
                })
                return resolve(datas);
              });
            }
          },
          //分页加载tree下的人员
          loadDepMember(node,data){
            let currentPage = JSON.parse(JSON.stringify(data.currentPage))
            let userId =  localStorage.getItem('_uid');
            let ids = this.employeeConfigTag.map(item=>{
              return item.id
            })
            let url = '/portal-api/department/corpListLazy-v2'+'?departId='+ data.parentId + '&userId=' + userId + '&corpId=' + this.selectedAgentInfo.corpId  + '&keyword=' + '' + '&page=' + currentPage + '&size=' + this.pageSize
            this.FetchGet(url).then((res) => {
              let datas = res && res.list ? res.list : [];
              datas.forEach(element => {
                if(element.type === 0) {
                  element.leaf = true
                }
              })
              let newDatas = this.handlerCheckedSelf(datas,ids)
              const parent = node.parent;
              const children = parent.data.children || parent.data;
              const index = children.findIndex(d => d.id === data.id);
              children.splice(index, 1);
              node.parent.data.children = [...node.parent.data.children,...newDatas];
              this.$refs.projectTree.remove(data.id)
            });
          },
            //获取钉钉应用下的部门和人
            loadDingMemberInfo(corpId, originSelectIds) {
                let url = "/portal-api/department/list?corpId=" + corpId;
                /*  window.location.href = 'https://test.signin.askbot.cn/logo-platform.png'*/
                this.FetchGet(url).then(res => {
                    console.debug("get ding memberInfo", res);
                    if (res.code == "0" && res.data) {
                        this.memberList = res.data;
                        if (this.memberList[0] && this.memberList[0].total < 300) {
                            this.defaultExpandAll = true;
                        }
                        this.memberList.forEach((item) => {
                            this.$set(item, "checked", false);
                            this.setChecked(item.children);
                        });
                        if (this.memberList.length > 0) {
                            this.loadMemberList(this.memberList[0], "RootNode");
                        }

                        if (originSelectIds) {
                            console.debug("originSelectIds", originSelectIds);
                            this.memberList.forEach((item) => {
                                if (originSelectIds.indexOf(String(item.id)) !== -1) {
                                    this.$set(item, "checked", true);
                                    this.setChecked(item.children);
                                }
                            });
                        }
                    }
                });
            },
            handleNodeClick(data, node) {
                if (node.dataType !== 'hasNext') {
                    /*if (data.type === 1 && data.children.length === 0) {
                        this.departmentMember[data.id] = {
                            isLastPage: true,
                            memberList: [],
                        };
                        this.loadMemberList(data);
                    } else {
                        if ((this.sendingRule === "NEW_EMPLOYEE" && data.type === 1) || this.sendingRule !== "NEW_EMPLOYEE") {
                            this.$set(data, "checked", !data.checked);
                            if (data.checked) {
                                this.userTags.push(data);
                            } else {
                                for (let i = 0; i < this.userTags.length; i++) {
                                    if (this.userTags[i].id === data.id) {
                                        this.userTags.splice(i, 1);
                                    }
                                }
                            }
                        }
                    }*/
                  if ((this.sendingRule === "NEW_EMPLOYEE" && node.type === 1) || this.sendingRule !== "NEW_EMPLOYEE") {
                    this.$set(node, "checked", !node.checked);
                    if (node.checked) {
                      this.userTags.push(node);
                    } else {
                      for (let i = 0; i < this.userTags.length; i++) {
                        if (this.userTags[i].id === node.id || this.userTags[i].id === node.label) {
                          this.userTags.splice(i, 1);
                        }
                      }
                    }
                  }
                  this.userTags = [...this.userTags];
                }
                else {
                  this.loadDepMember(data,node)
                }
            },
            updateKeyChildren(id, data) {
                this.$refs.projectTree.updateKeyChildren(id, data);
                const node = this.$refs.projectTree.getNode(id);
                node.childNodes.forEach((item) => {
                    item.expanded = false;
                    item.isLeaf = false;
                });
            },
            //搜索成员
            searchEmployee(type){
              if (!this.keyword){
                return
              }
              if (type !== 'load'){
                this.memberList = [];
                this.currentPage = 1
              }
              let userId = localStorage.getItem('_uid');
              let ids = this.employeeConfigTag && this.employeeConfigTag.map(item=>{
                return item.id
              })
              let url = '/portal-api/department/corpListLazy-v2?' + 'userId=' + userId + '&corpId=' + this.selectedAgentInfo.corpId  + '&keyword=' + this.keyword + '&page=' + this.currentPage + '&size=' + this.pageSize
              this.FetchGet(url).then(res=>{
                console.debug('get member',res)
                let list =  res.list? res.list:[];
                this.staffHasNext = res.hasNextPage;
                list.forEach(item=>{
                  this.$set(item,'checked',false)
                  if (item.type == 0){
                    this.$set(item,'leaf',true)
                  }
                  if (ids.includes(item.id) || ids.includes(item.label)){
                    this.$set(item,'checked',true)
                  }
                })
                this.memberList = [...this.memberList,...list];
                console.debug('memberList',this.memberList)
              })
            },
            searchKeyNull(){
                if (!this.keyword){
                  this.loadNode(this.dingTalkNode,this.dingTalkResolve)
                }
            },
            //加载更多
            loadMore(){
              this.currentPage+=1;
              this.searchEmployee('load')
            },
            setChecked(value) {
                if (value) {
                    value.forEach((item) => {
                        this.$set(item, "checked", false);
                        this.setChecked(item.children);
                    });
                } else return value;
            },
            /*遍历人员信息*/
            seachSelectUserIds(value, ids) {
                if (value !== null) {
                    for (let i = 0; i < value.length; i++) {
                        if (ids.indexOf(value[i].id) !== -1) {
                            this.employeeConfigTag.push(value[i]);
                            this.seachSelectUserIds(value[i].children, ids);
                        } else {
                            this.seachSelectUserIds(value[i].children, ids);
                        }
                    }
                } else return;

            },
            setActiveTreeIndex() {
                if (!this.selectedAgentId) {
                    this.$message({
                        message: "请先选择发送应用",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                this.memberDialogTableVisible = true;
                this.keyword = "";
                let arr = [];
                this.employeeConfigTag.forEach((item) => {
                    arr.push(item.id);
                });

                setTimeout(() => {
                    this.initShowOrTree(arr, true, "init");
                }, 100);
            },
            setActiveDingTreeIndex() {
                if (!this.selectedAgentId) {
                    this.$message({
                        message: "请先选择发送应用",
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                this.memberDialogDingTableVisible = true;
                this.keyword = ""
                let arr = [];
                this.employeeConfigTag.forEach((item) => {
                    arr.push(item.id);
                });

                setTimeout(() => {
                    this.initShowOrTree(arr, true, "init");
                }, 100);
            },
            initShowOrTree(handleIdArr, isChecked, type) {
                const formOrgTree = (arr, handleIdArr, isChecked) => {
                    if (type == "close") {
                        arr.forEach((item) => {
                            if (handleIdArr.indexOf(item.id) != -1) {
                                item.checked = isChecked;
                                if (item.children) {
                                    formOrgTree(
                                        item.children,
                                        handleIdArr,
                                        isChecked,
                                    );
                                }
                            } else {
                                if (item.children) {
                                    formOrgTree(
                                        item.children,
                                        handleIdArr,
                                        isChecked,
                                    );
                                }
                            }
                        });
                    } else {
                        arr.forEach((item) => {
                            if (handleIdArr.indexOf(item.id) != -1) {
                                item.checked = isChecked;
                                if (item.children) {
                                    formOrgTree(
                                        item.children,
                                        handleIdArr,
                                        isChecked,
                                    );
                                }
                            } else {
                                item.checked = !isChecked;
                                if (item.children) {
                                    formOrgTree(
                                        item.children,
                                        handleIdArr,
                                        isChecked,
                                    );
                                }
                            }
                        });
                    }

                    return arr;
                };
                this.memberList = [
                    ...formOrgTree(this.memberList, handleIdArr, isChecked),
                ];
            },
            handleCloseOrganization(tagCellIndex) {
                this.initShowOrTree(
                    [this.employeeConfigTag[tagCellIndex].id],
                    false,
                    "close",
                );
                this.employeeConfigTag.splice(tagCellIndex, 1);
            },
            dateHandle() {
                if (this.checkDateTime) {
                    var startAt = (new Date(this.checkDateTime) * 1000) / 1000;
                    if (startAt < Date.now()) {
                        this.checkDateTime = new Date();
                        this.$message({
                            message: "所选时间应大于当前时间",
                            type: "warning",
                            duration: 2000,
                        });
                    }
                }

            },
            openSelectCustomerQun() {
                this.SelectCustomerQunDialogVisible = true;
                this.$nextTick(() => {
                    let groupShow = [];
                    console.log(this.groupChatList);
                    for (let i = 0; i < this.groupIds.length; i++) {
                        this.groupChatList.forEach((item) => {
                            if (this.groupIds[i] === item.chat_id) {
                                this.$set(item, "checked", true);
                                groupShow.push(item);
                            }
                        });
                    }
                    this.$refs.selectCustomerQun.showGroup = groupShow;
                });
            },
        },
        mounted() {
            let id = this.$route.query.taskId;
            this.loadAgentList();
            this.getDingTalkGroup();
            this.getDingTalk();
            this.getYunzhijiaList();
            this.$nextTick(() => {
                if (id) {
                    this.headerTitle = "编辑任务";
                    this.getTaskDetail(id);
                }
            });

        },
    };
</script>

<style scoped lang="less">
    .taskContent {
        background: #F6F8FD;

        // /deep/.el-cascader-menu__wrap{
        //     height: 204px!important;
        // }

        .task-header {
            display: flex;
            align-items: center;
            width: 100%;
            background-color: white;
            height: 65px;
            margin-bottom: 20px;

            .task-header-left {
                display: flex;
                align-items: center;

                .header-back {
                    width: 65px;
                    height: 65px;
                    background-color: #AAB9E1;
                    margin-right: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 5px;

                    .iconfont {
                        color: white;
                        font-size: 18px;
                    }
                }

                .header--title-name {
                    font-size: 18px;
                }
            }

            .task-header-center {
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: center;

                .task-header-item {
                    flex: none;
                    width: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #000000;
                    padding-top: 25px;
                    cursor: pointer;

                    .header-name {
                        padding-bottom: 20px;
                        border-bottom: 2px solid #ffffff;
                        display: flex;
                        align-items: center;
                    }

                    .iconfont {
                        margin-right: 10px;
                    }
                }

                .active-header {
                    color: #366AFF;

                    .iconfont {
                        color: #366AFF;
                    }

                    .header-name {
                        border-bottom: 2px solid #366AFF;
                    }
                }
            }

            .task-header-right {
                flex: none;
                width: 120px;

                .publish-btn {
                    width: 80px;
                    height: 30px;
                    background: #366AFF;
                    border-radius: 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: 14px;
                    cursor: pointer;

                    i {
                        padding-right: 8px;
                    }
                }
            }

        }

        .task-container {
            .task-information {
                width: 40%;
                min-width: 620px;
                padding: 24px;
                margin: 0 auto;
                background-color: white;
                height: calc(100vh - 150px);
                overflow-y: scroll;
                text-align: left;

                /deep/ .el-form-item {
                    margin-bottom: 18px;
                }

                /deep/ .el-form-item__label {
                    line-height: 20px;
                    color: #616161;
                }

                /deep/ .el-radio__input.is-checked + .el-radio__label {
                    color: #366AFF;
                }

                /deep/ .el-radio__input.is-checked .el-radio__inner {
                    background-color: #366AFF;
                    border-color: #366AFF;
                }

                /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
                .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                    background-color: #366AFF;
                    border-color: #366AFF;
                }

                /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
                    color: #366AFF;
                }

                .select-channel {
                    padding: 10px;
                    background: #FBFCFD;
                    border-radius: 5px;
                }

                .send-form-box {
                    background: #FBFCFD;
                    padding: 14px 10px;
                    border-radius: 5px;
                    margin-top: 14px;

                    .sending-range {
                        margin-top: 14px;

                        .induction-days {
                            display: flex;
                            align-items: center;
                            padding-top: 20px;
                        }
                    }
                }

                .bind-bot-self-tree-box {
                    text-align: left;
                    flex: auto;
                    position: relative;
                    line-height: 28px;
                    height: 48px;

                    .selected-box {
                        border: solid 1px #e4e7ed;
                        height: 48px;
                        overflow-y: auto;
                        display: flex;
                        justify-content: space-between;

                        .tag-box {
                            height: 48px;
                            flex: auto;
                            background-color: white;

                            .placeholder {
                                height: 48px;
                                line-height: 48px;
                                color: #c0c4cc;
                                margin-left: 8px;
                            }

                            .el-tag {
                                margin-left: 6px;
                            }
                        }

                        .handle-teg-box {
                            flex: none;
                            width: 15px;
                            margin-left: 10px;
                            line-height: 100%;
                            cursor: pointer;
                            position: absolute;
                            right: 1px;
                            top: 20px;

                            span {
                                height: 10px;
                                flex: none;
                            }
                        }
                    }

                    .bind-bot-self-tree {
                        padding-top: 5px;
                        padding-left: 6px;
                        z-index: 99999999999999999;
                        position: relative;
                        top: 3px;
                        background-color: white;
                        border-left: solid 1px #e4e7ed;
                        border-right: solid 1px #e4e7ed;
                        border-bottom: solid 1px #e4e7ed;
                        border-radius: 6px;
                        max-height: 400px;
                        overflow: auto;
                        min-height: 200px;
                    }

                }

                .send-date {
                    .sendTime {
                        background: #FBFCFD;
                        border-radius: 5px;
                        padding: 0 10px;
                    }
                }

                .periodic-rule {
                    background: #FBFCFD;
                    border-radius: 5px;
                    padding: 0 10px;
                }

                .alert-text {
                    /* background-color: #fdf6ec;
                     color: #E6A23C;
                     padding: 0px 16px;
                     border-radius: 4px;
                     display: flex;

                     flex-direction: column;*/
                    margin-top: 10px;

                    a {
                        color: #E6A23C;
                    }

                    .alert-text-tip {
                        display: flex;
                        align-items: center;
                    }
                }

                .new-employee-induction {
                    width: 100%;
                    height: 40px;
                    background: #FBFCFD;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #366AFF;
                }
            }

            .task-sending-content {
                height: calc(100vh - 100px);
                overflow: hidden;
            }

            /deep/ .el-tag {
                color: #366AFF;
                background-color: #ebf0ff;
                border-color: #d7e1ff;
            }

            /deep/ .el-tag .el-tag__close {
                color: #366AFF;
            }
        }

        /deep/ .popup-floating-layer {
            text-align: left;

            #popup-assembly {
                width: 600px;

                .popup-container {
                    .departmentTreeNode {
                        max-height: calc(84vh - 200px);
                        overflow-y: scroll;
                        overflow-x: hidden;
                        padding-right: 20px;
                    }
                }
            }

            .member-footer {
                .el-button {
                    border-radius: 17px;
                }

                .el-button--primary {
                    background-color: #366AFF;
                    border-color: #366AFF;
                }
            }
        }

        .select-member {
            .ding-talk-member {
                max-height: calc(84vh - 200px);
                overflow-y: scroll;
                overflow-x: hidden;
                padding-right: 20px;
              /deep/.el-tree-node__content{
                height: 32px;
              }
            }
            .wechatMemberTree{
              max-height: calc(84vh - 200px);
              overflow-y: scroll;
              overflow-x: hidden;
              padding-right: 20px;
              /deep/.el-tree-node__content{
                height: 32px;
              }
            }
            .custom-tree-node {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                i {
                    font-size: 18px;
                    font-weight: 600;
                }
              .custom-tree-node-left{
                display: flex;
                align-items: center;
              }
            }
          .loadMore{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #366aff;
            cursor:pointer;
            margin-top: 8px;
          }
          /deep/.el-tree-node__expand-icon .is-leaf {
            color: #FFFFFF!important;
            cursor:default;
          }
        }
    }
</style>