<template>
  <div class="sendingRecords">
    <records-item :recordItem="item"
                  v-for="(item,index) in sendRecordList"
                  :taskRow="taskRow"
                  @closeDetail="closeDetail"
    ></records-item>
  </div>
</template>

<script>
import RecordsItem from "@/views/fissionMarketing/components/recordsItem";
export default {
  name: "sendingRecords",
  components: { RecordsItem },
  props:["sendRecordList","taskRow"],
  mounted() {
    this.$nextTick(()=>{
      console.debug('sendRecordList',this.sendRecordList)
    })

  },
  methods:{
    closeDetail(){
      this.$emit('closeDetail')
    }
  }
};
</script>

<style scoped lang="less">
.sendingRecords{
  padding: 10px 20px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>