<template>
    <div id="header-assembly">
        <slot name="setup-header-left">
            <span class="setup-header-left-icon"><i class="iconfont guoran-a-18-14"></i></span>
            <span>设置 - </span>
            <span>{{headerTitle}}</span>
        </slot>
        <div class="setup-header-right">
            <slot class="setup-header-right-l" name="setup-header-right-l"></slot>
            <div class="setup-header-right-r">
                <div class="help-document" @mouseenter="enterClick">
                    <slot name="help-document">
                        <el-tooltip class="help-item" effect="dark" placement="bottom" v-model="orderLength"
                                    :offset="-100">
                            <div slot="content">
                                <span v-show="showContent">上传下载中心</span>
                                <div v-show="showContentToop">导出任务已提交,请前往
                                    <span @click="gotoDownLoad"
                                          style="color: #366AFF;cursor: pointer">下载中心</span>查看进度
                                </div>
                                <div v-show="deriveScreen">导入任务已提交,请前往
                                    <span @click="goUpLoad"
                                          style="color: #366AFF;cursor: pointer">上传中心</span>查看进度
                                </div>
                            </div>
                            <el-link type="primary" class="help-link" :underline="false" @click="gotoDownLoad()">
                                <!--<em
                                        class="arsenal_icon arsenalxiazai"
                                        :style="(IsActive?'color: #366AFF;':'color: #ffffff;')"
                                        style="font-size: 26px;"
                                ></em>-->
                                <el-badge class="item">
                                    <i class="iconfont guoran-a-24-02"></i>
                                </el-badge>
                            </el-link>
                        </el-tooltip>
                    </slot>
                </div>
                <div class="personal">
                    <el-dropdown @command="topHandleCommand">
                        <span class="el-dropdown-link">
                            <span class="head_portrait">
                               <el-avatar :size="37" :src="userInfo.profilePhoto"></el-avatar>
                            </span>
                        </span>
                        <el-dropdown-menu slot="dropdown" style="min-width:150px;">
                            <el-dropdown-item
                                    style="color: black;word-break: break-word;border-bottom: 2px solid #f5f8fb;line-height: 30px">
                                {{userInfo.realName?userInfo.realName:userInfo.username?userInfo.username:""}}
                            </el-dropdown-item>
                            <el-dropdown-item command="a" class="borderBottom">
                                <i class="el-icon-user-solid" style="font-size: 14px;"></i>个人信息
                            </el-dropdown-item>
                            <el-dropdown-item command="business" class="borderBottom"
                                              v-if="roles === '1' || roles === '6'">
                                <i class="el-icon-office-building"
                                   style="font-size: 14px;"></i>企业管理
                            </el-dropdown-item>
                            <el-dropdown-item command="change" class="borderBottom" v-if="roleVisbled">
                                <i class="arsenal_icon arsenalzhuanjie" style="font-size: 14px;"></i>切换系统
                            </el-dropdown-item>
                            <el-dropdown-item command="logout">
                                <i class="el-icon-switch-button"
                                   style="font-size: 14px;"></i>退出登录
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { redirectUri } from "../../../const/redirectUri";
    import { rediect } from "@/const/rediect";
    import { redirect2LoginPage } from "@/const/redirectUri";

    export default {
        name: "top-header",
        data() {
            return {
                userInfo: {
                    profilePhoto: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                },
                roles: "",
                deriveScreen: false,
                showContentToop: false,
                showContent: false,
                tooltipContent: "上传下载中心",
                orderLength: false,
            };
        },
        props: ["circleUrl", "headerTitle", "upload", "showFilter"],
        mounted() {
            this.getUserInfo();
        },
        computed: {

            roleVisbled: function () {
                let flag = 0;
                if (this.RP_Visible("PORTAL_ENTRANCE_OPEN") || this.RP_Visible("OPEN")) {
                    flag += 1;
                }
                if (this.RP_Visible("PORTAL_ENTRANCE_IM")) {
                    flag += 1;
                }
                if (this.RP_Visible("PORTAL_MANAGEMENT")) {
                    flag += 1;
                }
                if (this.RP_Visible("PORTAL_ENTRANCE_WORKORDER")) {
                    flag += 1;
                }
                if (flag >= 2)
                    return true;
            },
        },
        methods: {
            getUserInfo() {
                let uid = localStorage.getItem("_uid");
                this.FetchGet("/portal-api/account/appoint/" + uid).then(res => {
                    if (res.code === "0") {
                        this.userInfo = res.data;
                    }
                });
                this.roles = localStorage.getItem("roleId");
            },
            topHandleCommand(command) {
                if (command === "logout") {
                    localStorage.removeItem("token");
                    this.FetchDelete2(this.requestUrl.auth.ssoLogout, {}).then(res => {
                        if (res.code === "0") {
                            redirect2LoginPage();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
                if (command === "a") {
                    this.$router.push({ path: "personal" });
                }
                if (command === "business") {
                    let windo = window.open("_blank");
                    windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].portal;
                    windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].test;
                }
                if (command === "change") {
                    /* let windo= window.open('_blank')
                     windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].portal;*/
                    console.log(rediect[process.env.VUE_APP_CONFIG_ENV].portal);
                    window.open(rediect[process.env.VUE_APP_CONFIG_ENV].portal);
                }
            },
            enterClick() {
                this.tooltipContent = "上传下载中心";
                this.showContent = true;
                this.showContentToop = false;
                this.deriveScreen = false;
                let cla = document.getElementsByClassName("is-dark");
                if (cla[cla.length] && cla[cla.length].style) {
                    cla[cla.length].style.display = "block";
                }
            },
            showTip() {
                let cla = document.getElementsByClassName("is-dark");
                if (cla[cla.length] && cla[cla.length].style) {
                    cla[cla.length].style.display = "block";
                }
                this.tooltipContent = "上传下载中心";
                this.showContent = false;
                this.showContentToop = false;
                this.deriveScreen = false;
                this.orderLength = false;

            },
            // 跳转到下载中心页面
            gotoDownLoad() {
                this.$router.push({ path: "downLoad" });
            },
            goUpLoad() {
                this.$router.push({ path: "upLoad" });
            },
        },
    };
</script>

<style scoped lang="less">
    #header-assembly {
        background-color: #FFFFFF;
        height: 62px;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        padding: 0 20px;

        .setup-header-left {
            padding-left: 15px;
            font-size: 15px;
            color: #000000;
            display: flex;
            align-items: center;

            .setup-header-left-icon {
                margin-right: 9px;
            }

        }

        .setup-header-left-filter {
            flex: none;
            width: 34px;
            height: 34px;
            background-color: #F5F7FB;
            border-radius: 5px;
            margin-right: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            border: 1px solid #ffffff;

            .close-iconfont {
                position: absolute;
                top: -6px;
                right: -6px;

                i {
                    font-size: 16px;
                    color: #D2D8E3;
                }
            }

            i {
                color: #AAB9E1;
            }

        }

        .setup-header-left-border {
            border: 1px solid #A1B9FF !important;

            i {
                color: #366AFF !important;
            }
        }

        .setup-header-right {
            display: flex;
            align-items: center;

            .setup-header-right-l {
                padding-right: 20px;

                .setup-header-right-l-btn {
                    width: 100px;
                    height: 33px;
                    background: #366AFF;
                    border-radius: 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    cursor: pointer;

                    i {
                        padding-right: 4px;
                    }
                }
            }

            .setup-header-right-r {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .iconfont {
                    font-size: 24px;
                    padding-right: 20px;
                    color: #404040;;
                }

                /deep/ .el-badge__content {
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background-color: #366AFF;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                /deep/ .el-badge__content.is-fixed {
                    right: 0;
                    left: -6px;
                }
            }

            /*.guoran-a-16-13{
                font-size: 14px;
            }*/
        }
    }
</style>