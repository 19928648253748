<template>
    <div id="preview">
        <div class="preview-box">
            <div class="content-box">
                <div v-for="(item,index) in welcomeConfig.attachmentList"
                     class="message-box"
                >
                    <div class="chatbot-message">
                        <div v-if="item.msgtype==='text'" v-html="item.text.content">
                        </div>
                        <div v-else-if="item.msgtype==='link'">
                            <div>
                                <el-image :src="item[item.msgtype].picurl"
                                          fit="cover"
                                          v-if="item[item.msgtype].picurl"
                                ></el-image>
                                <div
                                        class="no-cover"
                                        v-else
                                >暂无封面
                                </div>
                            </div>
                            <div style="font-weight: 600" v-html="item[item.msgtype].title">
                            </div>
                            <div v-html="item[item.msgtype].desc">
                            </div>
                        </div>
                        <div v-else-if="item.msgtype==='external_link'">
                            <div>
                                <el-image :src="item.externalLink.picurl"
                                          fit="cover"
                                          v-if="item.externalLink.picurl"
                                ></el-image>
                                <div
                                        class="no-cover"
                                        v-else
                                >暂无封面
                                </div>
                            </div>
                            <div style="font-weight: 600" v-html="item.externalLink.title">
                            </div>
                            <div  v-html="item.externalLink.desc">
                            </div>
                        </div>
                        <el-image v-else-if="item.msgtype==='image'"
                                  :src="item[item.msgtype].url"
                                  fit="cover"
                        ></el-image>
                        <div v-else-if="item.msgtype==='video'">
                            <img src="https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/35657870.jpg"
                                 style="width: 220px;border-radius: 0 15px 15px 15px">
                            <div style="font-weight: 600;word-break: break-all">{{ item.name }}</div>
                        </div>
                        <div v-else-if="item.msgtype == 'botcard'">
                            <div class="mini-program-img">
                                <el-image
                                        fit="cover"
                                        :src="item[item.msgtype].picurl"
                                        v-if="item[item.msgtype].picurl"
                                ></el-image>
                                <div
                                        class="no-cover"
                                        v-else
                                >暂无封面
                                </div>
                            </div>
                            <div class="mini-program-title" style="font-weight: 600" v-html="item.botcard.title">
                            </div>
                            <div class="mini-program-title" v-html="item.botcard.desc">
                            </div>
                        </div>
                        <div v-else-if="item.msgtype==='file'">
                            <div style="font-weight: 600;word-break: break-all">{{ item[item.msgtype].name }}</div>
                            <span style="word-break: break-all">{{ item[item.msgtype].url }}</span>
                        </div>
                        <div v-else-if="item.msgtype === 'messagecard'||
                            item.msgtype==='weathercard'||
                            item.msgtype==='datacard'"
                             class="card-style"
                        >
                            <div class="card-content" v-html="item[item.msgtype].content">
                            </div>
                            <div class="btn-list">
                                <div class="btn-item"
                                     v-for="(btn,index) in item[item.msgtype].btnList"
                                     :key="index"
                                >
                                    {{btn.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "preview",
        data() {
            return {};
        },
        props: ["welcomeConfig"],

        methods: {
            richContent(value) {
                if (value) {
                    return value.replace(/<[^>]+>/g, "");
                }
            },
        },
    };
</script>

<style scoped lang="less">
    #preview {
        background: url("../../../assets/images/examplesbg.png") no-repeat;
        background-size: 320px 650px;
        width: 320px;
        height: calc(100vh - 100px);
        background-position: center top;
        position: relative;

        .preview-box {
            position: absolute;
            top: 80px;
            left: 30px;

            .content-box {
                margin: 10px 0px;
                height: 520px;
                width: 270px;
                overflow-y: auto;
                overflow-x: hidden;
                text-align: left;

                .message-box {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    margin: 10px 0px;

                    .chatbot-message {
                        background-color: #FFFFFF;
                        padding: 10px;
                        border: 1px solid #E9E9EB;
                        border-radius: 0px 20px 20px 20px;
                        max-width: 220px;
                        text-align: left;
                        word-break: break-word;
                        .no-cover {
                            background-color: #F5F7FA;
                            color: #C0C4CC;
                            height: 90px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 150px;
                        }

                        /deep/ .el-image__inner {
                            width: 220px;
                            height: 110px;
                           /* height: 200px;*/
                            object-fit: cover;
                            border-radius: 0 10px 10px 10px;
                        }

                        /deep/ .el-image-viewer__canvas {
                            img {
                                max-height: calc(100vh - 300px) !important;
                                object-fit: contain;
                            }
                        }

                        .card-style {
                            min-width: 180px;
                            .card-content{
                                padding-bottom: 16px;
                            }
                            .btn-list {
                                .btn-item {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #366AFF;
                                    padding: 8px 0;
                                }
                                .btn-item:nth-last-child(1){
                                    padding-bottom: 0;
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
</style>