<template>
    <div id="messSendTable">
        <el-table
                :data="tableData"
                height="calc(100vh - 170px)"
                style="width: 100%"
                :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400,borderRadius:' 5px 5px 0px 0px'}"
                :row-style="{color:'#000000',lineHeight:'68px',height: '68px'}"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.6)"
        >
            <el-table-column
                    prop="name"
                    label="任务名称"
                    min-width="120"
            >
            </el-table-column>
            <el-table-column
                    prop="channel"
                    align="left"
                    label="发送渠道"
                    min-width="80"
            >
                <template slot-scope="scope">
                    <div>{{scope.row | channel }}</div>
                </template>
            </el-table-column>
            <el-table-column
                    min-width="80"
                    prop="sendNumber"
                    align="center"
                    label="发送人数"
            >
                <template slot-scope="scope">
                    <div>{{ scope.row | sendNumber }}</div>
                </template>
            </el-table-column>
            <el-table-column
                    min-width="80"
                    prop="creater"
                    align="center"
                    label="创建人"
            >
                <template slot-scope="scope">
                    <div>
                        <span v-if="scope.row.creatorInfo">{{ scope.row.creatorInfo.realName }}</span>
                        <span v-else>--</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="date"
                    label="日期"
                    align="center"
                    width="180"
            >
                <template slot-scope="scope">
                    <div class="create-date">
                        <i class="iconfont guoran-a-12-09"></i>
                        <span>
                            {{
                            new Date(
                            scope.row.createTime,
                            ).Format(
                            "yyyy-MM-dd hh:mm:ss",
                            )
                            }}
                        </span>

                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    width="140"
            >
                <template slot-scope="scope">
                    <div class="operation-button">
                        <el-switch
                                v-model="scope.row.open"
                                class="captcha"
                                @click.native="taskIsOpen(scope.row.open,scope.row.id)"
                                disabled
                                active-color="#366aff"
                        >
                        </el-switch>
                        <el-tooltip
                                class="item"
                                effect="dark"
                                content="编辑任务"
                                placement="top"
                        >
                            <i class="iconfont guoran-a-16-15" @click="editTask(scope.row)"></i>
                        </el-tooltip>
                        <el-tooltip
                                class="item"
                                effect="dark"
                                content="删除任务"
                                placement="top"
                        >
                            <i class="iconfont guoran-a-18-13" @click="deleteTask( scope.row.id)"></i>
                        </el-tooltip>
                        <el-tooltip
                                class="item"
                                effect="dark"
                                content="查看发送记录"
                                placement="top"
                        >
                            <i class="iconfont guoran-chakanshoufajilu" @click="handleClick(scope.row)"></i>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <pagination
                :pageSize="taskPageSize"
                :currentPage="taskPage"
                :total="taskTotal"
                @handleSizeChange="handleSizeChange"
                @currentChange="handleCurrentChange">
        </pagination>
    </div>
</template>

<script>
    import pagination from "../fissionMarketing/components/pagination";

    export default {
        name: "messSendTable",
        data() {
            return {
                tableData: [],
                taskPage: 1,
                taskPageSize: 10,
                taskTotal: 0,
                loading: true,
            };
        },
        components: { pagination },
        filters: {
            channel(value) {
                let channelName = "";
                if (value.channel === "WE_WORK") {
                    channelName = "企业微信";
                    if (value.weWorkEmployeeConfig && value.weWorkEmployeeConfig.agentName) {
                        channelName += " - " + value.weWorkEmployeeConfig.agentName;
                    }
                    return channelName;
                } else if (value.channel === "DING_TALK") {
                    channelName = "钉钉";
                    if (value.dingTalkAppConfig && value.dingTalkAppConfig.extInfo && value.dingTalkAppConfig.extInfo.dingTalkName) {
                        channelName += " - " + value.dingTalkAppConfig.extInfo.dingTalkName;
                    }
                    return channelName;
                } else if (value.channel === "DING_TALK_GROUP") {
                    channelName = "钉钉群";
                    if (value.dingTalkGroupConfig && value.dingTalkGroupConfig.extInfo && value.dingTalkGroupConfig.extInfo.dingTalkGroupName) {
                        channelName += " - " + value.dingTalkGroupConfig.extInfo.dingTalkGroupName;
                    }
                    return channelName;
                } else if (value.channel === 'YUN_ZHI_JIA'){
                  channelName = "云之家";
                  if (value.yunzhijiaConfig && value.yunzhijiaConfig.extInfo && value.yunzhijiaConfig.extInfo.yunzhijiaAgentName){
                    channelName += ' - ' + value.yunzhijiaConfig.extInfo.yunzhijiaAgentName
                  }
                  return channelName
                }
            },
            crodType(value) {
                if (value === "EMPLOYEE") return "员工";
                if (value === "CUSTOMER") {
                    return "客户";
                }
                if (value === "CUSTOMER_GROUP") {
                    return "客户群";
                }
            },
            sendNumber(value) {
                if (value) {
                    if (value.crowd === "EMPLOYEE") {
                        if (value.weWorkEmployeeConfig && value.weWorkEmployeeConfig.userIds) {
                            return value.weWorkEmployeeConfig.userIds.length;
                        }
                    }
                }
            },

        },
        methods: {
            //获取群发任务
            getTask() {
                let data = Object.assign({}, this.taskPage, {
                    page: this.taskPage - 1,
                });
                let url =
                    "/scrm-api/group-send-message-task/page?page=" +
                    data.page +
                    "&size=" +
                    this.taskPageSize;
                this.FetchGet(url).then((res) => {
                    this.tableData = res.content;
                    console.log("我是数据", this.tableData);
                    this.taskTotal = res.totalElements;
                    this.loading = false;
                });
            },
            //开启关闭任务
            taskIsOpen(value, id) {
                console.log(value);
                let url = "";
                if (!value) {
                    this.$confirm("是否开启任务?", "开启任务", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        url = "/scrm-api/group-send-message-task/open/";
                        this.FetchPut(url, id).then(res => {
                            this.$message({
                                message: "任务打开",
                                duration: 2000,
                                type: "success",
                            });
                            this.getTask();
                        });
                    }).catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消开启任务",
                        });
                    });

                } else {
                    this.$confirm("是否关闭任务?", "关闭任务", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        url = "/scrm-api/group-send-message-task/close/";
                        this.FetchPut(url, id).then(res => {
                            this.$message({
                                message: "任务关闭",
                                duration: 2000,
                                type: "success",
                            });
                            this.getTask();
                        });
                    }).catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消关闭任务",
                        });
                    });

                }
            },
            //查看发送记录
            handleClick(value) {
                this.$emit("handleClick", value);
            },
            //编辑任务
            editTask(item) {
                this.$router.push({ path: "taskContent", query: { taskId: item.id } });
            },
            //删除任务
            deleteTask(id) {
                this.$confirm("确定删除任务, 是否继续?", "删除任务", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        let url = "/scrm-api/group-send-message-task/" + id;
                        this.FetchDelete2(url).then((res) => {
                            this.$message({
                                message: "删除任务成功",
                                duration: 2000,
                                type: "success",
                            });
                            this.getTask();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            handleSizeChange(value) {
                this.taskPageSize = value;
                this.getTask();
            },
            handleCurrentChange(value) {
                this.taskPage = value;
                this.getTask();
            },
        },
        mounted() {
            this.getTask();
        },
    };
</script>

<style scoped lang="less">
    #messSendTable {
        /*position: relative;*/
        display: flex;
        align-items: center;
      flex: 1;
      height: calc(100vh - 172px);
      .el-table{
        position: absolute;
      }
        /*width: calc(100vw - 90px);*/

        .create-date {
            display: flex;
            align-items: center;

            .guoran-a-12-09 {
                padding-right: 10px;
                color: #D2D8E3;
                font-size: 14px;
            }
        }

        .operation-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #366AFF;

            .el-switch.is-disabled {
                opacity: 1 !important;
            }

            .el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label {
                cursor: pointer !important;
            }
        }
        /deep/.el-pagination{
            padding: 2px 5px!important;
        }
    }
</style>