<template>
    <div id="insertVariable">
        <div class="variable-header">
            <div class="variable-header-left">
                <span>变量</span>
                <span @click="closeVariable"><i class="el-icon-close"></i></span>
            </div>
            <div class="tips">从下方下选择要附加至内容的变量，之后转换为相关数据</div>
        </div>
        <div class="variable-group">
            <div class="variable-group-item"
                 v-for="(item,index) in variableList"
                 :key="index"

            >
                <div class="group-name">{{item.name}}</div>
                <div v-if="item.children" class="variable-list">
                    <div v-for="(items,index1) in item.children"
                         :key="index1"
                         class="variable-item"
                         @click="selectVariable(items,item.type)"
                    >
                        <span>{{items.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "insertVariable",
        props: ["variableList"],
        methods: {
            closeVariable() {
                this.$emit("closeVariable");
            },
            selectVariable(item,type) {
                this.$emit("selectVariable", item,type);
            },
        },
    };
</script>

<style scoped lang="less">
    #insertVariable {
        padding: 20px;
        text-align: left;

        .variable-header {
            text-align: left;

            .variable-header-left {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .el-icon-close {
                    font-size: 20px;
                }
            }

            .tips {
                padding-top: 6px;
                padding-bottom: 10px;
                color: #616161;
                font-size: 12px;
            }
        }

        .variable-group {
            border: 1px solid #F2F2F2;
            padding: 10px;

            .variable-group-item {
                margin-bottom: 20px;

                .group-name {
                    font-weight: 500;
                    padding-bottom: 4px;
                    color: #616161;
                }

                .variable-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .variable-item {
                        padding: 2px 8px;
                        border-radius: 5px;
                        border: 1px solid #cfd7df;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 6px;
                        margin-bottom: 4px;
                        margin-top: 4px;
                        background-color: #f5f7f9;
                        color: #12344d;
                        cursor: pointer;
                    }
                }
            }

        }

    }
</style>